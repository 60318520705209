import { Union, Record } from "../fable_modules/fable-library-js.4.19.2/Types.js";
import { SetId_$reflection, ToolId_$reflection, ConsumableId_$reflection } from "./Shared.js";
import { list_type, class_type, option_type, union_type, record_type, string_type } from "../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { ReservationDto_$reflection } from "./Planning.js";

export class MinimumQuantityReminderConsumable extends Record {
    constructor(Id, CurrentStock, StorageName, DisplayName, MinimumQuantityStock, Unit) {
        super();
        this.Id = Id;
        this.CurrentStock = CurrentStock;
        this.StorageName = StorageName;
        this.DisplayName = DisplayName;
        this.MinimumQuantityStock = MinimumQuantityStock;
        this.Unit = Unit;
    }
}

export function MinimumQuantityReminderConsumable_$reflection() {
    return record_type("Shared.Dashboard.MinimumQuantityReminderConsumable", [], MinimumQuantityReminderConsumable, () => [["Id", ConsumableId_$reflection()], ["CurrentStock", string_type], ["StorageName", string_type], ["DisplayName", string_type], ["MinimumQuantityStock", string_type], ["Unit", string_type]]);
}

export class MinimumQuantityReminderToolWithQuantity extends Record {
    constructor(Id, CurrentStock, StorageName, DisplayName, MinimumQuantityStock) {
        super();
        this.Id = Id;
        this.CurrentStock = CurrentStock;
        this.StorageName = StorageName;
        this.DisplayName = DisplayName;
        this.MinimumQuantityStock = MinimumQuantityStock;
    }
}

export function MinimumQuantityReminderToolWithQuantity_$reflection() {
    return record_type("Shared.Dashboard.MinimumQuantityReminderToolWithQuantity", [], MinimumQuantityReminderToolWithQuantity, () => [["Id", ToolId_$reflection()], ["CurrentStock", string_type], ["StorageName", string_type], ["DisplayName", string_type], ["MinimumQuantityStock", string_type]]);
}

export class MinimumQuantityReminder extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["MinimumQuantityConsumable", "MinimumQuantityToolWithQuantity"];
    }
}

export function MinimumQuantityReminder_$reflection() {
    return union_type("Shared.Dashboard.MinimumQuantityReminder", [], MinimumQuantityReminder, () => [[["Item", MinimumQuantityReminderConsumable_$reflection()]], [["Item", MinimumQuantityReminderToolWithQuantity_$reflection()]]]);
}

export class ReceiverAssignment extends Record {
    constructor(ReceiverId, ReceiverName) {
        super();
        this.ReceiverId = ReceiverId;
        this.ReceiverName = ReceiverName;
    }
}

export function ReceiverAssignment_$reflection() {
    return record_type("Shared.Dashboard.ReceiverAssignment", [], ReceiverAssignment, () => [["ReceiverId", string_type], ["ReceiverName", string_type]]);
}

export class CurrentAssignment extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Storage", "Receiver"];
    }
}

export function CurrentAssignment_$reflection() {
    return union_type("Shared.Dashboard.CurrentAssignment", [], CurrentAssignment, () => [[["Item", string_type]], [["Item", ReceiverAssignment_$reflection()]]]);
}

export class ReminderDateInfoType extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["ToolReminder", "ToolWithQuantityReminder", "SetReminder", "ConsumableReminder"];
    }
}

export function ReminderDateInfoType_$reflection() {
    return union_type("Shared.Dashboard.ReminderDateInfoType", [], ReminderDateInfoType, () => [[["Item", ToolId_$reflection()]], [["Item", ToolId_$reflection()]], [["Item", SetId_$reflection()]], [["Item", ConsumableId_$reflection()]]]);
}

export class ReminderDateInfo extends Record {
    constructor(ReminderDateInfoType, ToolTitle, ToolSubtitle, CurrentAssignment, Description, Date$) {
        super();
        this.ReminderDateInfoType = ReminderDateInfoType;
        this.ToolTitle = ToolTitle;
        this.ToolSubtitle = ToolSubtitle;
        this.CurrentAssignment = CurrentAssignment;
        this.Description = Description;
        this.Date = Date$;
    }
}

export function ReminderDateInfo_$reflection() {
    return record_type("Shared.Dashboard.ReminderDateInfo", [], ReminderDateInfo, () => [["ReminderDateInfoType", ReminderDateInfoType_$reflection()], ["ToolTitle", string_type], ["ToolSubtitle", string_type], ["CurrentAssignment", option_type(CurrentAssignment_$reflection())], ["Description", string_type], ["Date", class_type("System.DateTime")]]);
}

export class ToolReturnDateInfo extends Record {
    constructor(ReturnDate, ReceiverName, ReceiverId, ToolTitle, ToolSubtitle, ToolId) {
        super();
        this.ReturnDate = ReturnDate;
        this.ReceiverName = ReceiverName;
        this.ReceiverId = ReceiverId;
        this.ToolTitle = ToolTitle;
        this.ToolSubtitle = ToolSubtitle;
        this.ToolId = ToolId;
    }
}

export function ToolReturnDateInfo_$reflection() {
    return record_type("Shared.Dashboard.ToolReturnDateInfo", [], ToolReturnDateInfo, () => [["ReturnDate", class_type("System.DateTime")], ["ReceiverName", string_type], ["ReceiverId", string_type], ["ToolTitle", string_type], ["ToolSubtitle", string_type], ["ToolId", ToolId_$reflection()]]);
}

export class ProblemReportInfo extends Record {
    constructor(Date$, SenderName, SenderId, Description, ToolTitle, ToolSubtitle, ToolId) {
        super();
        this.Date = Date$;
        this.SenderName = SenderName;
        this.SenderId = SenderId;
        this.Description = Description;
        this.ToolTitle = ToolTitle;
        this.ToolSubtitle = ToolSubtitle;
        this.ToolId = ToolId;
    }
}

export function ProblemReportInfo_$reflection() {
    return record_type("Shared.Dashboard.ProblemReportInfo", [], ProblemReportInfo, () => [["Date", class_type("System.DateTime")], ["SenderName", string_type], ["SenderId", string_type], ["Description", string_type], ["ToolTitle", string_type], ["ToolSubtitle", string_type], ["ToolId", ToolId_$reflection()]]);
}

export class InInMaintenanceInfoType extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Tool", "Set"];
    }
}

export function InInMaintenanceInfoType_$reflection() {
    return union_type("Shared.Dashboard.InInMaintenanceInfoType", [], InInMaintenanceInfoType, () => [[["Item", ToolId_$reflection()]], [["Item", SetId_$reflection()]]]);
}

export class InInMaintenanceInfo extends Record {
    constructor(ToolInfoType, Title, Subtitle, CurrentAssignment, Date$) {
        super();
        this.ToolInfoType = ToolInfoType;
        this.Title = Title;
        this.Subtitle = Subtitle;
        this.CurrentAssignment = CurrentAssignment;
        this.Date = Date$;
    }
}

export function InInMaintenanceInfo_$reflection() {
    return record_type("Shared.Dashboard.InInMaintenanceInfo", [], InInMaintenanceInfo, () => [["ToolInfoType", InInMaintenanceInfoType_$reflection()], ["Title", string_type], ["Subtitle", string_type], ["CurrentAssignment", option_type(CurrentAssignment_$reflection())], ["Date", class_type("System.DateTime")]]);
}

export class DashboardData extends Record {
    constructor(InMaintenanceToolsAndSets, Problems, ReminderDates, ToolReturnDates, ReservationInfos, MinimumQuantityReminder) {
        super();
        this.InMaintenanceToolsAndSets = InMaintenanceToolsAndSets;
        this.Problems = Problems;
        this.ReminderDates = ReminderDates;
        this.ToolReturnDates = ToolReturnDates;
        this.ReservationInfos = ReservationInfos;
        this.MinimumQuantityReminder = MinimumQuantityReminder;
    }
}

export function DashboardData_$reflection() {
    return record_type("Shared.Dashboard.DashboardData", [], DashboardData, () => [["InMaintenanceToolsAndSets", list_type(InInMaintenanceInfo_$reflection())], ["Problems", list_type(ProblemReportInfo_$reflection())], ["ReminderDates", list_type(ReminderDateInfo_$reflection())], ["ToolReturnDates", list_type(ToolReturnDateInfo_$reflection())], ["ReservationInfos", list_type(ReservationDto_$reflection())], ["MinimumQuantityReminder", list_type(MinimumQuantityReminder_$reflection())]]);
}

