import { replace } from "../fable_modules/fable-library-js.4.19.2/RegExp.js";
import { printf, toText } from "../fable_modules/fable-library-js.4.19.2/String.js";
import { ApiResponse$1, I24Packages } from "./Shared.js";
import { date, equals, compare } from "../fable_modules/fable-library-js.4.19.2/Date.js";

export const emailRegex = "^([0-9a-zA-Z]([-.\\w]*[0-9a-zA-Z])*@([0-9a-zA-Z][-\\w]*[0-9a-zA-Z]\\.)+[a-zA-Z]{2,9})$";

export const bicRegex = "^[A-Z]{6,6}[A-Z2-9][A-NP-Z0-9]([A-Z0-9]{3,3}){0,1}$";

export const priceRegex = "^\\d+([.,]\\d{1,2})?$";

export const floatStorageStockRegex = "^[0-9]{1,}([,.][0-9]{1,})?$";

export function removeAllWhitespacesFromString(value) {
    return replace(value.trim(), "\\s", "");
}

export function unwrapStorageId(_arg) {
    return _arg.fields[0];
}

export function unwrapToolId(_arg) {
    return _arg.fields[0];
}

export function unwrapToolReservationId(_arg) {
    return _arg.fields[0];
}

export function unwrapConsumableId(_arg) {
    return _arg.fields[0];
}

export function unwrapConsumableReservationId(_arg) {
    return _arg.fields[0];
}

export function unwrapDocumentId(_arg) {
    return _arg.fields[0];
}

export function unwrapDispolistId(_arg) {
    return _arg.fields[0];
}

export function unwrapStocktakingId(_arg) {
    return _arg.fields[0];
}

export function unwrapDispolistSnapshotId(_arg) {
    return _arg.fields[0];
}

export function unwrapNotificationToken(_arg) {
    return _arg.fields[0];
}

export function unwrapNoticeId(_arg) {
    return _arg.fields[0];
}

export function unwrapLocationId(_arg) {
    return _arg.fields[0];
}

export function unwrapUserId(_arg) {
    return _arg.fields[0];
}

export function unwrapProblemReportId(_arg) {
    return _arg.fields[0];
}

export function unwrapLabelId(_arg) {
    return _arg.fields[0];
}

export function unwrapReminderId(_arg) {
    return _arg.fields[0];
}

export function unwrapPropertyConfigurationId(_arg) {
    return _arg.fields[0];
}

export function unwrapAdditionalReminderId(_arg) {
    return _arg.fields[0];
}

export function roleToString(role) {
    switch (role) {
        case "administrator":
            return "administrator";
        case "toolManager":
            return "toolmanager";
        case "user":
            return "user";
        default:
            return "system";
    }
}

export function roleFromString(role) {
    switch (role) {
        case "system":
            return "system";
        case "administrator":
            return "administrator";
        case "toolmanager":
            return "toolManager";
        case "user":
            return "user";
        default:
            throw new Error(toText(printf("invalid role: %s"))(role));
    }
}

export function currentBookedPackageI24(package$) {
    switch (package$.Name) {
        case "i24_large":
            return new I24Packages(1, []);
        case "i24_individual":
            return new I24Packages(2, []);
        default:
            return new I24Packages(0, []);
    }
}

export function between(startDate, endDate, testDate) {
    if (compare(testDate, startDate) >= 0) {
        return compare(testDate, endDate) <= 0;
    }
    else {
        return false;
    }
}

export function isSameDay(startDate, endDate, testStartDate, testEndDate) {
    if (((equals(date(startDate), date(endDate)) && equals(date(testStartDate), date(startDate))) && equals(date(testStartDate), date(endDate))) && equals(date(testEndDate), date(endDate))) {
        return equals(date(testEndDate), date(startDate));
    }
    else {
        return false;
    }
}

export function areRangesInBetween(startDate, endDate, testStartDate, testEndDate) {
    if (equals(startDate, endDate)) {
        if (compare(testStartDate, startDate) <= 0) {
            return compare(startDate, testEndDate) <= 0;
        }
        else {
            return false;
        }
    }
    else if (((compare(testStartDate, startDate) >= 0) && (compare(testStartDate, endDate) <= 0)) ? true : ((compare(testEndDate, startDate) >= 0) && (compare(testEndDate, endDate) <= 0))) {
        return true;
    }
    else if (((compare(testStartDate, startDate) <= 0) && (compare(testStartDate, endDate) <= 0)) && (compare(testEndDate, endDate) >= 0)) {
        return compare(testEndDate, startDate) >= 0;
    }
    else {
        return false;
    }
}

export function doRangesOverlap(startDate, endDate, testStartDate, testEndDate) {
    if ((between(startDate, endDate, testStartDate) ? true : between(startDate, endDate, testEndDate)) ? true : areRangesInBetween(startDate, endDate, testStartDate, testEndDate)) {
        return true;
    }
    else {
        return isSameDay(startDate, endDate, testStartDate, testEndDate);
    }
}

export function mapToApiResponse(internalApiResponse) {
    switch (internalApiResponse.tag) {
        case 1:
            return new ApiResponse$1(1, [internalApiResponse.fields[0]]);
        case 2:
            return new ApiResponse$1(3, [internalApiResponse.fields[0]]);
        case 3:
            return new ApiResponse$1(5, [internalApiResponse.fields[0]]);
        case 4:
            return new ApiResponse$1(6, [internalApiResponse.fields[0]]);
        default:
            throw new Error("Not allowed to call in ok case.");
    }
}

export function changeResponseType(apiResponse) {
    switch (apiResponse.tag) {
        case 1:
            return new ApiResponse$1(1, [apiResponse.fields[0]]);
        case 2:
            return new ApiResponse$1(2, [apiResponse.fields[0]]);
        case 3:
            return new ApiResponse$1(3, [apiResponse.fields[0]]);
        case 5:
            return new ApiResponse$1(5, [apiResponse.fields[0]]);
        case 4:
            return new ApiResponse$1(4, []);
        case 6:
            return new ApiResponse$1(6, [apiResponse.fields[0]]);
        default:
            throw new Error("Not allowed to call in ok case.");
    }
}

