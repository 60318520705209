import { Union, Record } from "./fable_modules/fable-library-js.4.19.2/Types.js";
import { union_type, string_type, record_type, option_type, tuple_type, class_type, bool_type } from "./fable_modules/fable-library-js.4.19.2/Reflection.js";
import { Url_$reflection } from "./Routes.js";
import { Testphase_$reflection, AccountSettings_$reflection, CustomerConfiguration_$reflection, RequestedValue$1_$reflection, TokenDataDto_$reflection } from "./Shared/Shared.js";

export class State extends Record {
    constructor(IsInTestphase, CurrentUrl, UserData, Configuration, AccountSettings) {
        super();
        this.IsInTestphase = IsInTestphase;
        this.CurrentUrl = CurrentUrl;
        this.UserData = UserData;
        this.Configuration = Configuration;
        this.AccountSettings = AccountSettings;
    }
}

export function State_$reflection() {
    return record_type("Client.Types.State", [], State, () => [["IsInTestphase", tuple_type(bool_type, class_type("System.DateTime"))], ["CurrentUrl", Url_$reflection()], ["UserData", RequestedValue$1_$reflection(option_type(TokenDataDto_$reflection()))], ["Configuration", RequestedValue$1_$reflection(CustomerConfiguration_$reflection())], ["AccountSettings", RequestedValue$1_$reflection(AccountSettings_$reflection())]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["LoginSuccessfull", "RemoveLoginParameter", "ParseToken", "Logout", "UrlChanged", "TestphaseFetched", "ConfigurationFetched", "AccountSettingsFetched", "FetchError"];
    }
}

export function Msg_$reflection() {
    return union_type("Client.Types.Msg", [], Msg, () => [[["Item", string_type]], [], [], [], [["Item", Url_$reflection()]], [["Item", Testphase_$reflection()]], [["Item", CustomerConfiguration_$reflection()]], [["Item", AccountSettings_$reflection()]], [["Item", class_type("System.Exception")]]]);
}

