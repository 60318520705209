import { Record } from "./fable_modules/fable-library-js.4.19.2/Types.js";
import { string_type, record_type, int32_type } from "./fable_modules/fable-library-js.4.19.2/Reflection.js";
import { ofNullable } from "./fable_modules/fable-library-js.4.19.2/Option.js";
import { Auto_generateBoxedDecoder_Z6670B51, fromString } from "./fable_modules/Thoth.Json.10.2.0/Decode.fs.js";
import { uncurry2 } from "./fable_modules/fable-library-js.4.19.2/Util.js";
import { Auto_generateBoxedEncoder_437914C6, toString } from "./fable_modules/Thoth.Json.10.2.0/Encode.fs.js";

export const initialPageSizeItemKey = "initialPageSize";

export const userdataKey = "mwk_userdata";

export class TablePaginationConfiguration extends Record {
    constructor(ToolsSizePerPage, ToolBoxSizePerPage, ConsumableSizePerPage, UserSizePerPage, SubcontractorSizePerPage, StorageSizePerPage) {
        super();
        this.ToolsSizePerPage = (ToolsSizePerPage | 0);
        this.ToolBoxSizePerPage = (ToolBoxSizePerPage | 0);
        this.ConsumableSizePerPage = (ConsumableSizePerPage | 0);
        this.UserSizePerPage = (UserSizePerPage | 0);
        this.SubcontractorSizePerPage = (SubcontractorSizePerPage | 0);
        this.StorageSizePerPage = (StorageSizePerPage | 0);
    }
}

export function TablePaginationConfiguration_$reflection() {
    return record_type("Client.LocalStorageHelper.TablePaginationConfiguration", [], TablePaginationConfiguration, () => [["ToolsSizePerPage", int32_type], ["ToolBoxSizePerPage", int32_type], ["ConsumableSizePerPage", int32_type], ["UserSizePerPage", int32_type], ["SubcontractorSizePerPage", int32_type], ["StorageSizePerPage", int32_type]]);
}

export class LocalStorageUserData extends Record {
    constructor(TablePaginationConfiguration, SelectedLanguage) {
        super();
        this.TablePaginationConfiguration = TablePaginationConfiguration;
        this.SelectedLanguage = SelectedLanguage;
    }
}

export function LocalStorageUserData_$reflection() {
    return record_type("Client.LocalStorageHelper.LocalStorageUserData", [], LocalStorageUserData, () => [["TablePaginationConfiguration", TablePaginationConfiguration_$reflection()], ["SelectedLanguage", string_type]]);
}

export const emptyLocalStorageUserData = new LocalStorageUserData(new TablePaginationConfiguration(10, 10, 10, 10, 10, 10), "de");

export function initialPageSize(key) {
    const matchValue = ofNullable(localStorage.getItem(userdataKey));
    let matchResult, key_1, userData;
    if (matchValue == null) {
        matchResult = 1;
    }
    else if (key == null) {
        matchResult = 1;
    }
    else {
        matchResult = 0;
        key_1 = key;
        userData = matchValue;
    }
    switch (matchResult) {
        case 0: {
            const matchValue_2 = fromString(uncurry2(Auto_generateBoxedDecoder_Z6670B51(LocalStorageUserData_$reflection(), undefined, undefined)), userData);
            if (matchValue_2.tag === 1) {
                return 10;
            }
            else {
                const userData_1 = matchValue_2.fields[0];
                switch (key_1) {
                    case "tools":
                        return userData_1.TablePaginationConfiguration.ToolsSizePerPage | 0;
                    case "toolbox":
                        return userData_1.TablePaginationConfiguration.ToolBoxSizePerPage | 0;
                    case "consumables":
                        return userData_1.TablePaginationConfiguration.ConsumableSizePerPage | 0;
                    case "users":
                        return userData_1.TablePaginationConfiguration.UserSizePerPage | 0;
                    case "subcontractors":
                        return userData_1.TablePaginationConfiguration.SubcontractorSizePerPage | 0;
                    case "storages":
                        return userData_1.TablePaginationConfiguration.StorageSizePerPage | 0;
                    default:
                        return 10;
                }
            }
        }
        default:
            return 10;
    }
}

export function writeInitialPageSize(key, value) {
    let bind$0040, bind$0040_1, bind$0040_2, bind$0040_3, bind$0040_4, bind$0040_5;
    if (key == null) {
    }
    else {
        const key_1 = key;
        let updatedUserData;
        const matchValue = ofNullable(localStorage.getItem(userdataKey));
        if (matchValue == null) {
            updatedUserData = emptyLocalStorageUserData;
        }
        else {
            let matchValue_1;
            const json = matchValue;
            matchValue_1 = fromString(uncurry2(Auto_generateBoxedDecoder_Z6670B51(LocalStorageUserData_$reflection(), undefined, undefined)), json);
            if (matchValue_1.tag === 1) {
                updatedUserData = emptyLocalStorageUserData;
            }
            else {
                const userData_1 = matchValue_1.fields[0];
                updatedUserData = (new LocalStorageUserData((key_1 === "tools") ? ((bind$0040 = userData_1.TablePaginationConfiguration, new TablePaginationConfiguration(value, bind$0040.ToolBoxSizePerPage, bind$0040.ConsumableSizePerPage, bind$0040.UserSizePerPage, bind$0040.SubcontractorSizePerPage, bind$0040.StorageSizePerPage))) : ((key_1 === "toolbox") ? ((bind$0040_1 = userData_1.TablePaginationConfiguration, new TablePaginationConfiguration(bind$0040_1.ToolsSizePerPage, value, bind$0040_1.ConsumableSizePerPage, bind$0040_1.UserSizePerPage, bind$0040_1.SubcontractorSizePerPage, bind$0040_1.StorageSizePerPage))) : ((key_1 === "consumables") ? ((bind$0040_2 = userData_1.TablePaginationConfiguration, new TablePaginationConfiguration(bind$0040_2.ToolsSizePerPage, bind$0040_2.ToolBoxSizePerPage, value, bind$0040_2.UserSizePerPage, bind$0040_2.SubcontractorSizePerPage, bind$0040_2.StorageSizePerPage))) : ((key_1 === "users") ? ((bind$0040_3 = userData_1.TablePaginationConfiguration, new TablePaginationConfiguration(bind$0040_3.ToolsSizePerPage, bind$0040_3.ToolBoxSizePerPage, bind$0040_3.ConsumableSizePerPage, value, bind$0040_3.SubcontractorSizePerPage, bind$0040_3.StorageSizePerPage))) : ((key_1 === "subcontractors") ? ((bind$0040_4 = userData_1.TablePaginationConfiguration, new TablePaginationConfiguration(bind$0040_4.ToolsSizePerPage, bind$0040_4.ToolBoxSizePerPage, bind$0040_4.ConsumableSizePerPage, bind$0040_4.UserSizePerPage, value, bind$0040_4.StorageSizePerPage))) : ((key_1 === "storages") ? ((bind$0040_5 = userData_1.TablePaginationConfiguration, new TablePaginationConfiguration(bind$0040_5.ToolsSizePerPage, bind$0040_5.ToolBoxSizePerPage, bind$0040_5.ConsumableSizePerPage, bind$0040_5.UserSizePerPage, bind$0040_5.SubcontractorSizePerPage, value))) : userData_1.TablePaginationConfiguration))))), userData_1.SelectedLanguage));
            }
        }
        localStorage.setItem(userdataKey, toString(0, Auto_generateBoxedEncoder_437914C6(LocalStorageUserData_$reflection(), undefined, undefined, undefined)(updatedUserData)));
    }
}

export function writeLanguage(value) {
    let updatedUserData;
    const matchValue = ofNullable(localStorage.getItem(userdataKey));
    if (matchValue == null) {
        updatedUserData = emptyLocalStorageUserData;
    }
    else {
        let matchValue_1;
        const json = matchValue;
        matchValue_1 = fromString(uncurry2(Auto_generateBoxedDecoder_Z6670B51(LocalStorageUserData_$reflection(), undefined, undefined)), json);
        updatedUserData = ((matchValue_1.tag === 1) ? emptyLocalStorageUserData : (new LocalStorageUserData(matchValue_1.fields[0].TablePaginationConfiguration, value)));
    }
    localStorage.setItem(userdataKey, toString(0, Auto_generateBoxedEncoder_437914C6(LocalStorageUserData_$reflection(), undefined, undefined, undefined)(updatedUserData)));
}

export function currentLanguage(_arg) {
    const matchValue = ofNullable(localStorage.getItem(userdataKey));
    if (matchValue == null) {
        return "de";
    }
    else {
        let matchValue_1;
        const json = matchValue;
        matchValue_1 = fromString(uncurry2(Auto_generateBoxedDecoder_Z6670B51(LocalStorageUserData_$reflection(), undefined, undefined)), json);
        if (matchValue_1.tag === 1) {
            return "de";
        }
        else {
            const userData_1 = matchValue_1.fields[0];
            if (ofNullable(userData_1.SelectedLanguage) == null) {
                return "de";
            }
            else {
                return userData_1.SelectedLanguage;
            }
        }
    }
}

