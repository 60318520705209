import { State } from "./Types.js";
import { Cmd_none } from "../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";

export function init(tokenData) {
    return [new State(tokenData), Cmd_none()];
}

export function update(msg, state) {
    return [state, Cmd_none()];
}

