import { tryFind as tryFind_1, sortWith, mapIndexed, choose, reduce, singleton, ofArray, empty, contains, isEmpty, filter, map, toArray } from "../fable_modules/fable-library-js.4.19.2/List.js";
import { selectWithoutLabel, CommonPropsMultiSelect$1, multiSelectWithoutLabel, selectWithLabel, toNoOptionsMessage, CommonProps$1, OptionType$1 } from "../SharedComponents/ReactSelect.js";
import { stringHash, compare, int32ToString, createObj, safeHash, equals } from "../fable_modules/fable-library-js.4.19.2/Util.js";
import { ReportType, Helper_reportById, Helper_hasReportDaterangeOption, Helper_reports } from "../Shared/Report.js";
import { tryFind, append } from "../fable_modules/fable-library-js.4.19.2/Array.js";
import { flatten, unwrap, ofNullable, map as map_1, defaultArg, value as value_17 } from "../fable_modules/fable-library-js.4.19.2/Option.js";
import { ConsumableByCommissionnumberRow, Msg } from "./Types.js";
import { Helper_priceToString, Helper_quantityAsStringWithComma } from "../Shared/Tool.js";
import { Helper_unitToHumanString } from "../Shared/Consumable.js";
import { split, join, printf, toText } from "../fable_modules/fable-library-js.4.19.2/String.js";
import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { OnTableRowClick$1, ColumnDefinition$1, CellContent, Table } from "../Widgets/Table/Table.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { empty as empty_1, toArray as toArray_1, singleton as singleton_1, append as append_1, delay, toList } from "../fable_modules/fable-library-js.4.19.2/Seq.js";
import { defaultOf } from "../fable_modules/fable-library-js.4.19.2/Util.js";
import { TextButton, Button } from "../Components/Button.js";
import { toString } from "../fable_modules/fable-library-js.4.19.2/Date.js";
import { RouterModule_nav, RouterModule_encodeParts } from "../fable_modules/Feliz.Router.4.0.0/Router.fs.js";
import { unwrapConsumableId, unwrapToolId } from "../Shared/Helper.js";
import { ofSeq, FSharpMap__TryFind } from "../fable_modules/fable-library-js.4.19.2/Map.js";
import { parse } from "../fable_modules/fable-library-js.4.19.2/Guid.js";
import { SetId__get_unwrap, ToolId__get_unwrap, PropertyConfigurationId } from "../Shared/Shared.js";
import { Variant as Variant_3 } from "../Variant.js";
import { useReact_useEffectOnce_3A5B6456, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.2.7.0/React.fs.js";
import { sortAsc } from "../SharedComponents/NaturalOrder.js";
import { InfiniteTable } from "../Widgets/Table/InfiniteTable.js";
import { List_groupBy, List_distinctBy } from "../fable_modules/fable-library-js.4.19.2/Seq2.js";
import { Select, GroupedSelect } from "../Components/Select.js";
import { Skeleton } from "../Components/Skeleton.js";
import { InventoryAssignmentByDaterange } from "./CustomerReports/Figlass.js";
import { PrinzingBaustromReport } from "./CustomerReports/PrinzingBaustrom.js";
import { SwissAthleticReport } from "./CustomerReports/SwissAthletic.js";
import { ReportView } from "./Reports/InMaintenanceReportView.js";
import { InformationAlert } from "../Components/Alert.js";
import { TextValue } from "../Components/Text.js";
import { DatePicker } from "../Components/DatePicker.js";
import { initWithQueryParameter, update as update_1, init as init_1 } from "./State.js";
import { React_useElmish_Z6C327F2E } from "../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { SectionContainer } from "../Widgets/Container.js";

function reportSelectProps(props) {
    const options = toArray(map((r_1) => (new OptionType$1(r_1.I18NLabel, r_1.Id)), filter((r) => {
        if (isEmpty(r.OnlyVisibleForCustomer)) {
            return true;
        }
        else {
            return contains(props.State.Customer, r.OnlyVisibleForCustomer, {
                Equals: equals,
                GetHashCode: safeHash,
            });
        }
    }, Helper_reports)));
    return append([new CommonProps$1(1, [options]), new CommonProps$1(2, [(s) => {
        props.Dispatch(new Msg(4, [value_17(s).value]));
    }]), new CommonProps$1(5, [true]), new CommonProps$1(9, [false]), new CommonProps$1(6, ["report.report_select_placeholder"]), new CommonProps$1(11, ["z-50"]), new CommonProps$1(10, [(_arg) => toNoOptionsMessage("report.report_select_not_found", _arg)]), new CommonProps$1(11, ["flex-grow-1"])], [new CommonProps$1(4, [tryFind((o) => {
        const matchValue = props.State.SelectedReportId;
        if (matchValue == null) {
            return false;
        }
        else {
            return matchValue === o.value;
        }
    }, options)])]);
}

function CommissionnumberSelectProps(props) {
    const options = toArray(map((r) => (new OptionType$1(r.CommissionNumber, r)), props.Reports));
    return append([new CommonProps$1(0, [options]), new CommonProps$1(2, [(s) => {
        props.Dispatch(new Msg(3, [value_17(s).value]));
    }]), new CommonProps$1(5, [true]), new CommonProps$1(9, [false]), new CommonProps$1(6, ["report.commission_report_placeholder"]), new CommonProps$1(10, [(_arg) => toNoOptionsMessage("report.commission_report_not_found", _arg)]), new CommonProps$1(11, ["flex-grow-1"])], [new CommonProps$1(3, [tryFind((o) => {
        const matchValue = props.State.SelectedConsumableByCommissionnumber;
        if (matchValue == null) {
            return false;
        }
        else {
            return equals(matchValue, o.value);
        }
    }, options)])]);
}

function toConsumableByCommissionnumberRow(consumableResult) {
    let arg_1;
    const quantity = Helper_quantityAsStringWithComma(consumableResult.Quantity);
    return new ConsumableByCommissionnumberRow(consumableResult.Id, consumableResult.Name, (arg_1 = Helper_unitToHumanString(consumableResult.Unit), toText(printf("%s %s"))(quantity)(arg_1)));
}

function ConsumablesOverview(props) {
    let elems, TableRows;
    const t = useTranslation()[0];
    const children = singleton(createElement("div", createObj(ofArray([["id", "tools-table"], ["className", join(" ", ["bg-white", "flex", "flex-col"])], (elems = [createElement(Table, (TableRows = toArray(map(toConsumableByCommissionnumberRow, props.ConsumableResults)), {
        ColumnDefinitions: [new ColumnDefinition$1("name", "", t("general.Name"), (row) => (new CellContent(0, [row.Name])), "text", "text"), new ColumnDefinition$1("quantity", "", t("general.quantity"), (row_1) => (new CellContent(0, [`${row_1.Quantity}`])), "text", "text")],
        Controls: empty(),
        Options: {
            BorderStyle: "full",
            CSVDownload: true,
            ConfigurableColumns: false,
            DefaultSortColumn: ["name", "asc"],
            GlobalSearch: true,
            RowSelection: "disabled",
        },
        TableRows: TableRows,
    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))));
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

function ConsumableByCommissionnumberView(props) {
    let elems_4;
    const t = useTranslation()[0];
    return createElement("div", createObj(ofArray([["className", "flex-grow-1 d-flex flex-column"], (elems_4 = toList(delay(() => {
        let elems;
        return append_1(singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["d-flex", "flex-row", "mx-2", "mb-2"])], (elems = [selectWithLabel(CommissionnumberSelectProps({
            Dispatch: props.Dispatch,
            Reports: props.Reports,
            State: props.State,
        }), t("report.choose_commission_number"), "")], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))), delay(() => {
            let children, elems_1, Label;
            const matchValue = props.State.SelectedConsumableByCommissionnumber;
            if (matchValue == null) {
                return singleton_1(defaultOf());
            }
            else {
                const selectedConsumableByCommissionnumber = matchValue;
                return append_1(singleton_1((children = singleton(createElement("div", createObj(ofArray([["className", join(" ", ["d-flex", "ml-auto"])], (elems_1 = [createElement(Button, (Label = t("report.print_report"), {
                    ComponentState: (props.State.SelectedConsumableByCommissionnumber != null) ? "enabled" : "disabled",
                    Label: Label,
                    OnClick: () => {
                        props.Dispatch(new Msg(7, []));
                    },
                    TestId: "report-consumablebycomissionnumber-print-button",
                    Variant: "primary",
                }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))), createElement("div", {
                    children: Interop_reactApi.Children.toArray(Array.from(children)),
                }))), delay(() => {
                    let elems_3, elems_2;
                    return singleton_1(createElement("div", createObj(ofArray([["className", "d-flex flex-row"], (elems_3 = [createElement("div", createObj(ofArray([["className", "flex-grow-1"], (elems_2 = [createElement(ConsumablesOverview, {
                        ConsumableResults: selectedConsumableByCommissionnumber.Consumables,
                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]))));
                }));
            }
        }));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])));
}

function ProblemPerToolOverview(props) {
    let elems, TableRows;
    const t = useTranslation()[0];
    return createElement("div", createObj(ofArray([["id", "tools-table"], ["className", join(" ", ["flex", "flex-col"])], (elems = [createElement(Table, (TableRows = toArray(props.ProblemReportsPerTool), {
        ColumnDefinitions: [new ColumnDefinition$1("manufacturer", "", t("tool.manufacturer"), (row) => (new CellContent(0, [row.Tool.Manufacturer])), "text", "text"), new ColumnDefinition$1("model", "", t("tool.model"), (row_1) => (new CellContent(0, [row_1.Tool.Model])), "text", "text"), new ColumnDefinition$1("displayName", "", t("tool.displayname"), (row_2) => (new CellContent(0, [row_2.Tool.DisplayName])), "text", "text"), new ColumnDefinition$1("serialNumber", "", t("tool.serial_number"), (row_3) => (new CellContent(0, [row_3.Tool.SerialNumber])), "text", "text"), new ColumnDefinition$1("inventoryNumber", "", t("general.inventory_number"), (row_4) => (new CellContent(0, [row_4.Tool.InventoryNumber])), "text", "text"), new ColumnDefinition$1("qRCodeIds", "", t("general.qr_code"), (row_5) => (new CellContent(0, [isEmpty(row_5.Tool.QRCodeIds) ? "" : reduce((list_2, code) => (`${list_2}, ${code}`), row_5.Tool.QRCodeIds)])), "text", "text"), new ColumnDefinition$1("sumOfProblemReports", "", t("report.problem_report_sum"), (row_6) => (new CellContent(0, [int32ToString(row_6.SumOfProblemReports)])), "text", "text"), new ColumnDefinition$1("lastProblemReport", "", t("report.last_problem_report"), (row_7) => (new CellContent(0, [toString(row_7.LastProblemReport, "dd.MM.yyyy")])), "text", "text")],
        Controls: empty(),
        Options: {
            BorderStyle: "full",
            CSVDownload: true,
            ConfigurableColumns: false,
            DefaultSortColumn: ["manufacturer", "asc"],
            GlobalSearch: true,
            OnRowClick: new OnTableRowClick$1(1, [(row_8) => RouterModule_encodeParts(ofArray(["tools", unwrapToolId(row_8.Tool.Id)]), 1)]),
            RowSelection: "disabled",
        },
        TableRows: TableRows,
    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

function ProblemReportsPerToolView(props) {
    let elems;
    return createElement("div", createObj(ofArray([["className", "flex-grow-1 d-flex flex-column"], (elems = [createElement(ProblemPerToolOverview, {
        Dispatch: props.Dispatch,
        ProblemReportsPerTool: props.Reports,
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

function AppointmentsView(props) {
    let elems_2, elems_1, elems, TableRows;
    const t = useTranslation()[0];
    return createElement("div", createObj(ofArray([["className", "flex-grow-1 d-flex flex-column"], (elems_2 = [createElement("div", createObj(ofArray([["id", "appointments"], ["className", "tab-pane"], ["children", "Appointments"], (elems_1 = [createElement("div", createObj(ofArray([["id", "tools-table"], ["className", join(" ", ["flex", "flex-col"])], (elems = [createElement(Table, (TableRows = toArray(props.Reports), {
        ColumnDefinitions: toArray_1(delay(() => append_1(singleton_1(new ColumnDefinition$1("date", "", t("general.date"), (row) => (new CellContent(5, [row.ReminderDate])), "dateRange", "date")), delay(() => append_1((props.State.Customer.tag === 8) ? choose((propertyConfiguration) => {
            const matchValue_1 = propertyConfiguration.Type;
            switch (matchValue_1.tag) {
                case 0:
                    return new ColumnDefinition$1(propertyConfiguration.Name, "", propertyConfiguration.Name, (dto) => (new CellContent(0, [defaultArg(map_1((property) => property.Value, FSharpMap__TryFind(ofSeq(map((customProperty) => [new PropertyConfigurationId(parse(customProperty.Id)), customProperty], dto.CustomProperties), {
                        Compare: compare,
                    }), propertyConfiguration.Id)), "")])), "text", "text");
                default:
                    return undefined;
            }
        }, filter((row_1) => (row_1.Name.trim() === "Verantwortlich"), props.PropertyConfigurations)) : (empty_1()), delay(() => append_1(singleton_1(new ColumnDefinition$1("title", "", t("general.designation"), (row_2) => (new CellContent(0, [row_2.Title])), "text", "text")), delay(() => append_1(singleton_1(new ColumnDefinition$1("description", "", t("general.description"), (row_3) => (new CellContent(0, [row_3.Description])), "text", "text")), delay(() => append_1(singleton_1(new ColumnDefinition$1("serialNumber", "", t("tool.serial_number"), (row_4) => (new CellContent(0, [row_4.SerialNumber])), "text", "text")), delay(() => append_1((props.State.Customer.tag === 1) ? singleton_1(new ColumnDefinition$1("checkNumber", "", t("tool.prinzing.check_number"), (row_5) => (new CellContent(0, [row_5.CheckNumber])), "text", "text")) : (empty_1()), delay(() => append_1(singleton_1(new ColumnDefinition$1("displayName", "", t("general.tool"), (row_6) => (new CellContent(0, [row_6.DisplayName])), "text", "text")), delay(() => singleton_1(new ColumnDefinition$1("isConfirmed", "", t("general.done"), (row_7) => (new CellContent(0, [row_7.IsConfirmed ? t("general.yes") : t("general.no")])), "text", "text")))))))))))))))))),
        Controls: empty(),
        Options: {
            BorderStyle: "full",
            CSVDownload: true,
            ConfigurableColumns: false,
            DefaultSortColumn: ["date", "asc"],
            GlobalSearch: true,
            OnRowClick: new OnTableRowClick$1(1, [(tool) => {
                const matchValue_4 = tool.OpenAppointmentIdType;
                return (matchValue_4.tag === 0) ? RouterModule_encodeParts(ofArray(["tools", ToolId__get_unwrap(matchValue_4.fields[0])]), 1) : ((matchValue_4.tag === 1) ? RouterModule_encodeParts(ofArray(["toolswithquantity", ToolId__get_unwrap(matchValue_4.fields[0])]), 1) : ((matchValue_4.tag === 2) ? RouterModule_encodeParts(ofArray(["sets", SetId__get_unwrap(matchValue_4.fields[0])]), 1) : RouterModule_encodeParts(ofArray(["consumables", unwrapConsumableId(matchValue_4.fields[0])]), 1)));
            }]),
            RowSelection: "disabled",
        },
        TableRows: TableRows,
    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])));
}

function OpenAppointmentsView(props) {
    let elems_2, elems_1, elems, TableRows;
    const t = useTranslation()[0];
    return createElement("div", createObj(ofArray([["className", "flex-grow-1 d-flex flex-column"], (elems_2 = [createElement("div", createObj(ofArray([["id", "appointments"], ["className", "tab-pane"], ["children", "Appointments"], (elems_1 = [createElement("div", createObj(ofArray([["id", "tools-table"], ["className", join(" ", ["flex", "flex-col"])], (elems = [createElement(Table, (TableRows = toArray(props.Reports), {
        ColumnDefinitions: toArray_1(delay(() => append_1(singleton_1(new ColumnDefinition$1("date", "", t("general.date"), (row) => (new CellContent(5, [row.ReminderDate])), "dateRange", "date")), delay(() => append_1((props.State.Customer.tag === 8) ? choose((propertyConfiguration) => {
            const matchValue_1 = propertyConfiguration.Type;
            switch (matchValue_1.tag) {
                case 0:
                    return new ColumnDefinition$1(propertyConfiguration.Name, "", propertyConfiguration.Name, (dto) => (new CellContent(0, [defaultArg(map_1((property) => property.Value, FSharpMap__TryFind(ofSeq(map((customProperty) => [new PropertyConfigurationId(parse(customProperty.Id)), customProperty], dto.CustomProperties), {
                        Compare: compare,
                    }), propertyConfiguration.Id)), "")])), "text", "text");
                default:
                    return undefined;
            }
        }, filter((row_1) => (row_1.Name.trim() === "Verantwortlich"), props.PropertyConfigurations)) : (empty_1()), delay(() => append_1(singleton_1(new ColumnDefinition$1("title", "", t("general.designation"), (row_2) => (new CellContent(0, [row_2.Title])), "text", "text")), delay(() => append_1(singleton_1(new ColumnDefinition$1("description", "", t("general.description"), (row_3) => (new CellContent(0, [row_3.Description])), "text", "text")), delay(() => append_1(singleton_1(new ColumnDefinition$1("displayName", "", t("tool.displayname"), (row_4) => (new CellContent(0, [row_4.DisplayName])), "text", "text")), delay(() => append_1(singleton_1(new ColumnDefinition$1("serialNumber", "", t("tool.serial_number"), (row_5) => (new CellContent(0, [row_5.SerialNumber])), "text", "text")), delay(() => append_1((props.State.Customer.tag === 1) ? singleton_1(new ColumnDefinition$1("checkNumber", "", t("tool.prinzing.check_number"), (row_6) => (new CellContent(0, [row_6.CheckNumber])), "text", "text")) : (empty_1()), delay(() => append_1(singleton_1(new ColumnDefinition$1("manufacturer", "", t("tool.manufacturer"), (row_7) => (new CellContent(0, [row_7.Manufacturer])), "text", "text")), delay(() => append_1(singleton_1(new ColumnDefinition$1("model", "", t("tool.model"), (row_8) => (new CellContent(0, [row_8.Model])), "text", "text")), delay(() => append_1(singleton_1(new ColumnDefinition$1("inventoryNumber", "", t("general.inventory_number"), (row_9) => (new CellContent(0, [row_9.InventoryNumber])), "text", "text")), delay(() => append_1(singleton_1(new ColumnDefinition$1("receiverName", "", t("general.receiver"), (row_10) => {
            const matchValue_3 = row_10.CurrentReceiver;
            if (matchValue_3 != null) {
                const receiver = matchValue_3;
                return new CellContent(2, [createElement(TextButton, {
                    ComponentState: "enabled",
                    Label: receiver.ReceiverName,
                    OnClick: () => {
                        RouterModule_nav(ofArray(["receivers", receiver.ReceiverId]), 1, 1);
                    },
                    TestId: "receiver-text-button-test-id",
                    Variant: "blueButton",
                })]);
            }
            else {
                return new CellContent(0, [""]);
            }
        }, "text", "text")), delay(() => singleton_1(new ColumnDefinition$1("storageName", "", t("general.storage"), (row_11) => (new CellContent(0, [defaultArg(map_1((storageInformation) => storageInformation.StorageName, row_11.StorageInformation), "")])), "text", "text")))))))))))))))))))))))))),
        Controls: empty(),
        Options: {
            BorderStyle: "full",
            CSVDownload: true,
            ConfigurableColumns: false,
            DefaultSortColumn: ["date", "asc"],
            GlobalSearch: true,
            OnRowClick: new OnTableRowClick$1(1, [(tool) => {
                const matchValue_4 = tool.IdType;
                return (matchValue_4.tag === 0) ? RouterModule_encodeParts(ofArray(["tools", ToolId__get_unwrap(matchValue_4.fields[0])]), 1) : ((matchValue_4.tag === 1) ? RouterModule_encodeParts(ofArray(["toolswithquantity", ToolId__get_unwrap(matchValue_4.fields[0])]), 1) : ((matchValue_4.tag === 2) ? RouterModule_encodeParts(ofArray(["sets", SetId__get_unwrap(matchValue_4.fields[0])]), 1) : RouterModule_encodeParts(ofArray(["consumables", unwrapConsumableId(matchValue_4.fields[0])]), 1)));
            }]),
            RowSelection: "disabled",
        },
        TableRows: TableRows,
    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])));
}

function MissedAppointmentsView(props) {
    let elems_1, elems, TableRows;
    const t = useTranslation()[0];
    return createElement("div", createObj(ofArray([["className", "flex-grow-1 d-flex flex-column"], (elems_1 = [createElement("div", createObj(ofArray([["id", "appointments"], ["className", "tab-pane"], ["children", "Appointments"], (elems = [createElement(Table, (TableRows = toArray(props.Reports), {
        ColumnDefinitions: toArray_1(delay(() => append_1(singleton_1(new ColumnDefinition$1("date", "", t("general.date"), (row) => (new CellContent(5, [row.ReminderDate])), "dateRange", "date")), delay(() => append_1((props.State.Customer.tag === 8) ? choose((propertyConfiguration) => {
            const matchValue_1 = propertyConfiguration.Type;
            switch (matchValue_1.tag) {
                case 0:
                    return new ColumnDefinition$1(propertyConfiguration.Name, "", propertyConfiguration.Name, (dto) => (new CellContent(0, [defaultArg(map_1((property) => property.Value, FSharpMap__TryFind(ofSeq(map((customProperty) => [new PropertyConfigurationId(parse(customProperty.Id)), customProperty], dto.CustomProperties), {
                        Compare: compare,
                    }), propertyConfiguration.Id)), "")])), "text", "text");
                default:
                    return undefined;
            }
        }, filter((row_1) => (row_1.Name.trim() === "Verantwortlich"), props.PropertyConfigurations)) : (empty_1()), delay(() => append_1(singleton_1(new ColumnDefinition$1("title", "", t("general.designation"), (row_2) => (new CellContent(0, [row_2.Title])), "text", "text")), delay(() => append_1(singleton_1(new ColumnDefinition$1("description", "", t("general.description"), (row_3) => (new CellContent(0, [row_3.Description])), "text", "text")), delay(() => append_1(singleton_1(new ColumnDefinition$1("serialNumber", "", t("tool.serial_number"), (row_4) => (new CellContent(0, [row_4.SerialNumber])), "text", "text")), delay(() => append_1((Variant_3.tag === 0) ? ((props.State.Customer.tag === 1) ? singleton_1(new ColumnDefinition$1("checkNumber", "", t("tool.prinzing.check_number"), (row_5) => (new CellContent(0, [row_5.CheckNumber])), "text", "text")) : (empty_1())) : (empty_1()), delay(() => singleton_1(new ColumnDefinition$1("displayName", "", t("general.tool"), (row_6) => (new CellContent(0, [row_6.DisplayName])), "text", "text")))))))))))))))),
        Controls: empty(),
        Options: {
            BorderStyle: "full",
            CSVDownload: true,
            ConfigurableColumns: false,
            DefaultSortColumn: ["date", "asc"],
            GlobalSearch: true,
            OnRowClick: new OnTableRowClick$1(1, [(tool) => {
                const matchValue_5 = tool.OpenAppointmentIdType;
                return (matchValue_5.tag === 0) ? RouterModule_encodeParts(ofArray(["tools", ToolId__get_unwrap(matchValue_5.fields[0])]), 1) : ((matchValue_5.tag === 1) ? RouterModule_encodeParts(ofArray(["toolswithquantity", ToolId__get_unwrap(matchValue_5.fields[0])]), 1) : ((matchValue_5.tag === 2) ? RouterModule_encodeParts(ofArray(["sets", SetId__get_unwrap(matchValue_5.fields[0])]), 1) : RouterModule_encodeParts(ofArray(["consumables", unwrapConsumableId(matchValue_5.fields[0])]), 1)));
            }]),
            RowSelection: "disabled",
        },
        TableRows: TableRows,
    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
}

function ToolsCreatedByDateRangeOverview(props) {
    let elems, TableRows;
    const t = useTranslation()[0];
    return createElement("div", createObj(ofArray([["id", "tools-table"], (elems = [createElement(Table, (TableRows = toArray(props.Tools), {
        ColumnDefinitions: [new ColumnDefinition$1("manufacturer", "", t("tool.manufacturer"), (row) => (new CellContent(0, [row.Manufacturer])), "text", "text"), new ColumnDefinition$1("model", "", t("tool.model"), (row_1) => (new CellContent(0, [row_1.Model])), "text", "text"), new ColumnDefinition$1("displayName", "", t("tool.displayname"), (row_2) => (new CellContent(0, [row_2.DisplayName])), "text", "text"), new ColumnDefinition$1("serialNumber", "", t("tool.serial_number"), (row_3) => (new CellContent(0, [row_3.SerialNumber])), "text", "text"), new ColumnDefinition$1("inventoryNumber", "", t("general.inventory_number"), (row_4) => (new CellContent(0, [row_4.InventoryNumber])), "text", "text"), new ColumnDefinition$1("qRCodeIds", "", t("general.qr_code"), (row_5) => (new CellContent(0, [isEmpty(row_5.QRCodeIds) ? "" : reduce((list_2, code) => (`${list_2}, ${code}`), row_5.QRCodeIds)])), "text", "text"), new ColumnDefinition$1("createdAt", "", t("general.date"), (row_6) => (new CellContent(6, [row_6.CreatedAt])), "dateRange", "date")],
        Controls: empty(),
        Options: {
            BorderStyle: "full",
            CSVDownload: true,
            ConfigurableColumns: false,
            DefaultSortColumn: ["manufacturer", "asc"],
            GlobalSearch: true,
            OnRowClick: new OnTableRowClick$1(1, [(tool) => RouterModule_encodeParts(ofArray(["tools", unwrapToolId(tool.Id)]), 1)]),
            RowSelection: "disabled",
        },
        TableRows: TableRows,
    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

function SchoeffmannRetiredToolsWithQRCodeId(props) {
    let elems_1, elems, TableRows;
    const t = useTranslation()[0];
    return createElement("div", createObj(ofArray([["id", "tools-table"], (elems_1 = [createElement("div", createObj(ofArray([["id", "tools-table"], ["className", join(" ", ["flex", "flex-col"])], (elems = [createElement(Table, (TableRows = toArray(props.Tools), {
        ColumnDefinitions: [new ColumnDefinition$1("manufacturer", "", t("tool.manufacturer"), (row) => (new CellContent(0, [row.Manufacturer])), "text", "text"), new ColumnDefinition$1("model", "", t("tool.model"), (row_1) => (new CellContent(0, [row_1.Model])), "text", "text"), new ColumnDefinition$1("displayName", "", t("tool.displayname"), (row_2) => (new CellContent(0, [row_2.DisplayName])), "text", "text"), new ColumnDefinition$1("serialNumber", "", t("tool.serial_number"), (row_3) => (new CellContent(0, [row_3.SerialNumber])), "text", "text"), new ColumnDefinition$1("storageName", "", t("general.storage"), (row_4) => (new CellContent(0, [row_4.StorageName])), "text", "text"), new ColumnDefinition$1("qrCodeId", "", t("general.qr_code"), (row_5) => (new CellContent(0, [row_5.QRCodeId])), "text", "text"), new ColumnDefinition$1("retiredDate", "", t("tool.retired_at"), (row_6) => (new CellContent(6, [row_6.RetiredDate])), "dateRange", "date")],
        Controls: empty(),
        Options: {
            BorderStyle: "full",
            CSVDownload: true,
            ConfigurableColumns: false,
            DefaultSortColumn: ["manufacturer", "asc"],
            GlobalSearch: true,
            OnRowClick: new OnTableRowClick$1(1, [(tool) => RouterModule_encodeParts(ofArray(["tools", unwrapToolId(tool.ToolId)]), 1)]),
            RowSelection: "disabled",
        },
        TableRows: TableRows,
    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
}

export function CustomStateSelectFilter(props) {
    let options, selectedKeys;
    const patternInput = useFeliz_React__React_useState_Static_1505(props.SelectedElements);
    useReact_useEffectOnce_3A5B6456(() => {
        props.OnFilter(props.SelectedElements);
    });
    return multiSelectWithoutLabel((options = toArray(mapIndexed((index, value) => (new OptionType$1(value, int32ToString(index))), sortWith(sortAsc, props.States))), (selectedKeys = ofArray(split(patternInput[0], [","], undefined, 0)), [new CommonPropsMultiSelect$1(4, [options.filter((o) => contains(o.value, selectedKeys, {
        Equals: (x, y) => (x === y),
        GetHashCode: stringHash,
    }))]), new CommonPropsMultiSelect$1(1, [options]), new CommonPropsMultiSelect$1(2, [(u) => {
        const u_1 = defaultArg(ofNullable(u), []);
        const selectedElements = (u_1.length === 0) ? "" : reduce((e1, e2) => (`${e1},${e2}`), map((e) => e.value, ofArray(u_1)));
        patternInput[1](selectedElements);
        props.OnFilter(selectedElements);
    }]), new CommonPropsMultiSelect$1(5, [true]), new CommonPropsMultiSelect$1(9, [true]), new CommonPropsMultiSelect$1(8, [true]), new CommonPropsMultiSelect$1(10, [false]), new CommonPropsMultiSelect$1(6, ["select.state_placeholder"]), new CommonPropsMultiSelect$1(11, [(_arg_1) => toNoOptionsMessage("select.employee_no_options", _arg_1)]), new CommonPropsMultiSelect$1(12, ["assigned-user-select"])])));
}

function SchoeffmannActiveAndInMaintenanceToolsWithQRCodeId(props) {
    let elems, TableRows;
    const t = useTranslation()[0];
    return createElement("div", createObj(ofArray([["id", "tools-table"], ["className", join(" ", ["flex", "flex-col"])], (elems = [createElement(Table, (TableRows = toArray(props.Tools), {
        ColumnDefinitions: [new ColumnDefinition$1("manufacturer", "", t("tool.manufacturer"), (row) => (new CellContent(0, [row.Manufacturer])), "text", "text"), new ColumnDefinition$1("model", "", t("tool.model"), (row_1) => (new CellContent(0, [row_1.Model])), "text", "text"), new ColumnDefinition$1("displayName", "", t("tool.displayname"), (row_2) => (new CellContent(0, [row_2.DisplayName])), "text", "text"), new ColumnDefinition$1("serialNumber", "", t("tool.serial_number"), (row_3) => (new CellContent(0, [row_3.SerialNumber])), "text", "text"), new ColumnDefinition$1("storageName", "", t("general.storage"), (row_4) => (new CellContent(0, [row_4.StorageName])), "text", "text"), new ColumnDefinition$1("qrCodeId", "", t("general.qr_code"), (row_5) => (new CellContent(0, [row_5.QRCodeId])), "text", "text"), new ColumnDefinition$1("status", "", t("general.state"), (row_6) => (new CellContent(0, [t(row_6.State)])), "select", "text")],
        Controls: empty(),
        Options: {
            BorderStyle: "full",
            CSVDownload: true,
            ConfigurableColumns: false,
            DefaultSortColumn: ["manufacturer", "asc"],
            GlobalSearch: true,
            OnRowClick: new OnTableRowClick$1(1, [(tool) => RouterModule_encodeParts(ofArray(["tools", unwrapToolId(tool.ToolId)]), 1)]),
            RowSelection: "disabled",
        },
        TableRows: TableRows,
    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

function ReservationsByDaterangeReport(props) {
    let elems, TableRows;
    const t = useTranslation()[0];
    return createElement("div", createObj(ofArray([["id", "tools-table"], (elems = [createElement(Table, (TableRows = toArray(props.Tools), {
        ColumnDefinitions: [new ColumnDefinition$1("manufacturer", "", t("tool.manufacturer"), (row) => (new CellContent(0, [row.Manufacturer])), "text", "text"), new ColumnDefinition$1("model", "", t("tool.model"), (row_1) => (new CellContent(0, [row_1.Model])), "text", "text"), new ColumnDefinition$1("displayName", "", t("tool.displayname"), (row_2) => (new CellContent(0, [row_2.DisplayName])), "text", "text"), new ColumnDefinition$1("serialNumber", "", t("tool.serial_number"), (row_3) => (new CellContent(0, [row_3.SerialNumber])), "text", "text"), new ColumnDefinition$1("reservationCreatedBy", "", t("general.sender"), (row_4) => (new CellContent(0, [row_4.ReservationCreatedBy])), "text", "text"), new ColumnDefinition$1("reservationForUser", "", t("tool.reserved_for"), (row_5) => (new CellContent(0, [row_5.ReservationForUser])), "text", "text"), new ColumnDefinition$1("startDate", "", t("general.start_date"), (row_6) => (new CellContent(6, [row_6.StartDate])), "dateRange", "date"), new ColumnDefinition$1("endDate", "", t("general.end_date"), (row_7) => (new CellContent(6, [row_7.EndDate])), "dateRange", "date")],
        Controls: empty(),
        Options: {
            BorderStyle: "full",
            CSVDownload: true,
            ConfigurableColumns: false,
            DefaultSortColumn: ["manufacturer", "asc"],
            GlobalSearch: true,
            OnRowClick: new OnTableRowClick$1(1, [(tool) => {
                const matchValue = tool.SetId;
                return (matchValue == null) ? RouterModule_encodeParts(ofArray(["tools", ToolId__get_unwrap(tool.ToolId)]), 1) : RouterModule_encodeParts(ofArray(["sets", SetId__get_unwrap(matchValue)]), 1);
            }]),
            RowSelection: "disabled",
        },
        TableRows: TableRows,
    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

function WithoutReservationsByDateRangeReport(props) {
    let elems, TableRows;
    const t = useTranslation()[0];
    return createElement("div", createObj(ofArray([["id", "tools-table"], ["className", join(" ", ["flex", "flex-col"])], (elems = [createElement(Table, (TableRows = toArray(props.Tools), {
        ColumnDefinitions: [new ColumnDefinition$1("manufacturer", "", t("tool.manufacturer"), (row) => (new CellContent(0, [row.Manufacturer])), "text", "text"), new ColumnDefinition$1("model", "", t("tool.model"), (row_1) => (new CellContent(0, [row_1.Model])), "text", "text"), new ColumnDefinition$1("displayName", "", t("tool.displayname"), (row_2) => (new CellContent(0, [row_2.DisplayName])), "text", "text"), new ColumnDefinition$1("serialNumber", "", t("tool.serial_number"), (row_3) => (new CellContent(0, [row_3.SerialNumber])), "text", "text")],
        Controls: empty(),
        Options: {
            BorderStyle: "full",
            CSVDownload: true,
            ConfigurableColumns: false,
            DefaultSortColumn: ["manufacturer", "asc"],
            GlobalSearch: true,
            OnRowClick: new OnTableRowClick$1(1, [(tool) => RouterModule_encodeParts(ofArray(["tools", ToolId__get_unwrap(tool.ToolId)]), 1)]),
            RowSelection: "disabled",
        },
        TableRows: TableRows,
    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

export function CustomAssignedToSelectFilter(props) {
    let options, selectedKeys;
    const patternInput = useFeliz_React__React_useState_Static_1505(props.SelectedElements);
    useReact_useEffectOnce_3A5B6456(() => {
        props.OnFilter(props.SelectedElements);
    });
    return multiSelectWithoutLabel((options = toArray(mapIndexed((index, value) => (new OptionType$1(value, int32ToString(index))), sortWith(sortAsc, props.Users))), (selectedKeys = ofArray(split(patternInput[0], [","], undefined, 0)), [new CommonPropsMultiSelect$1(4, [options.filter((o) => contains(o.value, selectedKeys, {
        Equals: (x, y) => (x === y),
        GetHashCode: stringHash,
    }))]), new CommonPropsMultiSelect$1(1, [options]), new CommonPropsMultiSelect$1(2, [(u) => {
        const u_1 = defaultArg(ofNullable(u), []);
        const selectedElements = (u_1.length === 0) ? "" : reduce((e1, e2) => (`${e1},${e2}`), map((e) => e.value, ofArray(u_1)));
        patternInput[1](selectedElements);
        props.OnFilter(selectedElements);
    }]), new CommonPropsMultiSelect$1(5, [true]), new CommonPropsMultiSelect$1(9, [true]), new CommonPropsMultiSelect$1(8, [true]), new CommonPropsMultiSelect$1(10, [false]), new CommonPropsMultiSelect$1(6, ["select.employee_placeholder"]), new CommonPropsMultiSelect$1(11, [(_arg_1) => toNoOptionsMessage("select.employee_no_options", _arg_1)]), new CommonPropsMultiSelect$1(12, ["assigned-user-select"])])));
}

function PrinzingReportAssignedToolsWithLastComment(props) {
    let elems, TableRows;
    const t = useTranslation()[0];
    return createElement("div", createObj(ofArray([["id", "tools-table"], ["className", join(" ", ["h-full"])], (elems = [createElement(InfiniteTable, (TableRows = toArray(props.Tools), {
        ColumnDefinitions: [new ColumnDefinition$1("assignedUserName", "", t("dashboard.assigned_to"), (row) => (new CellContent(0, [row.AssignedUsername])), "text", "text"), new ColumnDefinition$1("lastComment", "", t("general.commission"), (row_1) => (new CellContent(0, [row_1.LastComment])), "text", "text"), new ColumnDefinition$1("manufacturer", "", t("tool.manufacturer"), (row_2) => (new CellContent(0, [row_2.Manufacturer])), "text", "text"), new ColumnDefinition$1("model", "", t("tool.model"), (row_3) => (new CellContent(0, [row_3.Model])), "text", "text"), new ColumnDefinition$1("serialNumber", "", t("tool.serial_number"), (row_4) => (new CellContent(0, [row_4.SerialNumber])), "text", "text"), new ColumnDefinition$1("displayName", "", t("tool.displayname"), (row_5) => (new CellContent(0, [row_5.DisplayName])), "text", "text"), new ColumnDefinition$1("checknumber", "", t("tool.prinzing.check_number"), (row_6) => (new CellContent(0, [row_6.Checknumber])), "text", "text"), new ColumnDefinition$1("quantity", "", t("general.quantity"), (row_7) => (new CellContent(0, [row_7.Quantity])), "text", "text"), new ColumnDefinition$1("toolType", "", t("general.tool_type"), (row_8) => (row_8.IsToolWithQuantity ? (new CellContent(0, [t("tool.simple_tool")])) : (new CellContent(0, [t("general.tool")]))), "text", "text"), new ColumnDefinition$1("assignedDate", "", t("report.assigned_at"), (row_9) => (new CellContent(6, [row_9.AssignedDate])), "text", "date")],
        Controls: empty(),
        Options: {
            BorderStyle: "full",
            CSVDownload: true,
            ConfigurableColumns: false,
            DefaultSortColumn: ["assignedUserName", "asc"],
            GlobalSearch: true,
            OnRowClick: new OnTableRowClick$1(1, [(tool) => RouterModule_encodeParts(ofArray([tool.IsToolWithQuantity ? "toolswithquantity" : "tools", ToolId__get_unwrap(tool.ToolId)]), 1)]),
            RowSelection: "disabled",
            WithQueryParameters: false,
        },
        TableRows: TableRows,
    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

function SchoeffmannUserReport(props) {
    let elems_2;
    const t = useTranslation()[0];
    let selectProps;
    const options = toArray(map((tupledArg) => (new OptionType$1(tupledArg[0], tupledArg[1])), map((r_1) => [r_1.UserName, r_1.UserId], List_distinctBy((r) => r.UserName, props.Reports, {
        Equals: (x, y) => (x === y),
        GetHashCode: stringHash,
    }))));
    selectProps = [new CommonProps$1(3, [tryFind((o) => {
        const matchValue = props.State.SelectedUserId;
        if (matchValue == null) {
            return false;
        }
        else {
            return o.value === matchValue[0];
        }
    }, options)]), new CommonProps$1(0, [options]), new CommonProps$1(2, [(u) => {
        if (u == null) {
            props.Dispatch(new Msg(5, [undefined]));
        }
        else {
            const user = u;
            props.Dispatch(new Msg(5, [[user.value, user.label]]));
        }
    }]), new CommonProps$1(5, [true]), new CommonProps$1(9, [false]), new CommonProps$1(6, ["select.employee_placeholder"]), new CommonProps$1(10, [(_arg) => toNoOptionsMessage("select.employee_no_options", _arg)]), new CommonProps$1(11, ["mechanic-select flex-grow-1"])];
    return createElement("div", createObj(singleton((elems_2 = toList(delay(() => append_1(singleton_1(selectWithoutLabel(selectProps)), delay(() => {
        let children, elems;
        return (props.State.SelectedUserId != null) ? append_1(singleton_1((children = singleton(createElement("div", createObj(ofArray([["className", join(" ", ["d-flex", "ml-auto", "mt-2"])], (elems = [createElement(Button, {
            ComponentState: "enabled",
            Label: t("report.print_report"),
            OnClick: () => {
                props.Dispatch(new Msg(7, []));
            },
            TestId: "report-schoeffmannuserreport-print-button",
            Variant: "blueButton",
        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))), createElement("div", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        }))), delay(() => {
            let elems_1, TableRows;
            return singleton_1(createElement("div", createObj(ofArray([["id", "tools-table"], ["className", join(" ", ["flex", "flex-col"])], (elems_1 = [createElement(Table, (TableRows = toArray(props.Reports), {
                ColumnDefinitions: [new ColumnDefinition$1("qrCodeId", "", t("general.qr_code"), (row) => (new CellContent(0, [row.QrCodeId])), "text", "text"), new ColumnDefinition$1("manufacturer", "", t("tool.manufacturer"), (row_1) => (new CellContent(0, [row_1.Manufacturer])), "text", "text"), new ColumnDefinition$1("model", "", t("tool.model"), (row_2) => (new CellContent(0, [row_2.Model])), "text", "text"), new ColumnDefinition$1("state", "", t("general.state"), (row_3) => (new CellContent(0, [row_3.State])), "text", "text"), new ColumnDefinition$1("serialNumber", "", t("tool.serial_number"), (row_4) => (new CellContent(0, [row_4.SerialNumber])), "text", "text"), new ColumnDefinition$1("lastInpsectionDate", "", t("tool.last_check"), (row_5) => (new CellContent(0, [row_5.LastInspectionDate])), "text", "text"), new ColumnDefinition$1("nextInspectionDate", "", t("tool.next_check"), (row_6) => (new CellContent(0, [row_6.NextInspectionDate])), "text", "text")],
                Controls: empty(),
                Options: {
                    BorderStyle: "full",
                    CSVDownload: true,
                    ConfigurableColumns: false,
                    DefaultSortColumn: ["qrCodeId", "asc"],
                    GlobalSearch: true,
                    OnRowClick: new OnTableRowClick$1(1, [(_arg_2) => RouterModule_encodeParts(ofArray(["tools"]), 1)]),
                    RowSelection: "disabled",
                },
                TableRows: TableRows,
            }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))));
        })) : singleton_1(defaultOf());
    })))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))]))));
}

function SBucherReport(props) {
    let elems_1;
    const t = useTranslation()[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(undefined);
    const setSelectedConsturctionSiteId = patternInput_1[1];
    const selectedConstructionId = patternInput_1[0];
    let selectProps;
    const options = toArray(map((tupledArg) => (new OptionType$1(tupledArg[0], tupledArg[1])), map((r_1) => [r_1.ConstructionSite, r_1.ConstructionSiteId], List_distinctBy((r) => r.ConstructionSite, props.Reports, {
        Equals: (x, y) => (x === y),
        GetHashCode: stringHash,
    }))));
    selectProps = [new CommonProps$1(3, [tryFind((o) => {
        if (selectedConstructionId == null) {
            return false;
        }
        else {
            return o.value === selectedConstructionId[0];
        }
    }, options)]), new CommonProps$1(0, [options]), new CommonProps$1(2, [(u) => {
        if (u == null) {
            setSelectedConsturctionSiteId(undefined);
        }
        else {
            const user = u;
            setSelectedConsturctionSiteId([user.value, user.label]);
        }
    }]), new CommonProps$1(5, [true]), new CommonProps$1(9, [false]), new CommonProps$1(6, ["select.construction_site_placeholder"]), new CommonProps$1(10, [(_arg) => toNoOptionsMessage("select.construction_site_no_options", _arg)]), new CommonProps$1(11, ["mechanic-select flex-grow-1"])];
    return createElement("div", createObj(singleton((elems_1 = toList(delay(() => append_1(singleton_1(selectWithoutLabel(selectProps)), delay(() => {
        let elems, TableRows;
        const matchValue = selectedConstructionId;
        if (matchValue != null) {
            const selectedConstructionId_1 = matchValue;
            return singleton_1(createElement("div", createObj(ofArray([["id", "tools-table"], ["className", join(" ", ["flex", "flex-col"])], (elems = [createElement(Table, (TableRows = toArray(filter((report) => (report.ConstructionSiteId === selectedConstructionId_1[0]), props.Reports)), {
                ColumnDefinitions: [new ColumnDefinition$1("manufacturer", "", t("tool.manufacturer"), (row) => (new CellContent(0, [row.Manufacturer])), "text", "text"), new ColumnDefinition$1("model", "", t("tool.model"), (row_1) => (new CellContent(0, [row_1.Model])), "text", "text"), new ColumnDefinition$1("inventorynumber", "", t("tool.inventory_number"), (row_2) => (new CellContent(0, [row_2.InventoryNumber])), "text", "text"), new ColumnDefinition$1("assignedDate", "", t("general.delivery_date"), (row_3) => (new CellContent(0, [row_3.AssignDate])), "text", "text"), new ColumnDefinition$1("returnDate", "", t("general.return_date"), (row_4) => {
                    let matchValue_1;
                    return new CellContent(0, [(matchValue_1 = row_4.ReturnDate, (matchValue_1 != null) ? matchValue_1 : "")]);
                }, "text", "text")],
                Controls: empty(),
                Options: {
                    BorderStyle: "full",
                    CSVDownload: true,
                    ConfigurableColumns: false,
                    DefaultSortColumn: ["manufacturer", "asc"],
                    GlobalSearch: true,
                    OnRowClick: new OnTableRowClick$1(1, [(tool) => RouterModule_encodeParts(ofArray(["tools", tool.ToolId]), 1)]),
                    RowSelection: "disabled",
                },
                TableRows: TableRows,
            }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))));
        }
        else {
            return singleton_1(defaultOf());
        }
    })))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))]))));
}

function WassernordConsumableReport(props) {
    let elems_2, elems, elems_1, TableRows;
    const t = useTranslation()[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(undefined);
    return createElement("div", createObj(singleton((elems_2 = [createElement("div", createObj(ofArray([["className", join(" ", [])], (elems = [createElement(GroupedSelect, (($value) => { $value.key = $value.Key; return $value; })({
        ComponentState: "enabled",
        IsClearable: false,
        Key: "select-receiver",
        NoOptionsMessage: "select.employee_no_options",
        OnChange: patternInput_1[1],
        Options: map((tupledArg) => {
            const report = tupledArg[1];
            if (tupledArg[0].tag === 1) {
                return {
                    label: "Baustelle",
                    options: toArray(List_distinctBy((r_3) => r_3.label, map((r_2) => ({
                        label: r_2.ReceiverName,
                        value: r_2,
                    }), report), {
                        Equals: (x_2, y_2) => (x_2 === y_2),
                        GetHashCode: stringHash,
                    })),
                };
            }
            else {
                return {
                    label: "Lager",
                    options: toArray(List_distinctBy((r_1) => r_1.label, map((r) => ({
                        label: r.ReceiverName,
                        value: r,
                    }), report), {
                        Equals: (x_1, y_1) => (x_1 === y_1),
                        GetHashCode: stringHash,
                    })),
                };
            }
        }, List_groupBy((receiver) => receiver.ReceiverType, props.Reports, {
            Equals: equals,
            GetHashCode: safeHash,
        })),
        PlaceholderKey: "select.employee_placeholder",
        Value: unwrap(patternInput_1[0]),
    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])]))), createElement("div", createObj(ofArray([["id", "wassernord-tools-table"], ["className", join(" ", ["flex", "flex-col"])], (elems_1 = [createElement(Table, (TableRows = toArray(props.Reports), {
        ColumnDefinitions: [new ColumnDefinition$1("name", "", t("general.name"), (row) => (new CellContent(0, [row.Name])), "text", "text"), new ColumnDefinition$1("additionalName", "", t("consumable.additional_name"), (row_1) => (new CellContent(0, [row_1.AdditionalName])), "text", "text"), new ColumnDefinition$1("supplierArticleNumber", "", t("general.supplier_article_number"), (row_2) => (new CellContent(0, [row_2.SupplierArticleNumber])), "text", "text"), new ColumnDefinition$1("senderName", "", t("general.sender"), (row_3) => (new CellContent(0, [row_3.SenderName])), "text", "text"), new ColumnDefinition$1("receiver", "", t("general.receiver"), (row_4) => (new CellContent(0, [row_4.ReceiverName])), "text", "text"), new ColumnDefinition$1("quatity", "", t("general.quantity_2"), (row_5) => (new CellContent(0, [row_5.Quantity.toString()])), "text", "text")],
        Controls: empty(),
        Options: {
            BorderStyle: "full",
            CSVDownload: true,
            ConfigurableColumns: false,
            DefaultSortColumn: ["name", "asc"],
            GlobalSearch: true,
            OnRowClick: new OnTableRowClick$1(1, [(_arg) => RouterModule_encodeParts(ofArray(["tools", "view", "toolwithquantity"]), 1)]),
            RowSelection: "disabled",
        },
        TableRows: TableRows,
    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))]))));
}

function ProblemReportsReport(props) {
    let elems, TableRows;
    const t = useTranslation()[0];
    return createElement("div", createObj(ofArray([["id", "problemreports-tools-table"], ["className", join(" ", ["flex", "flex-col"])], (elems = [createElement(Table, (TableRows = toArray(props.Reports), {
        ColumnDefinitions: [new ColumnDefinition$1("timestamp", "", t("general.date"), (row) => (new CellContent(5, [row.Timestamp])), "text", "date"), new ColumnDefinition$1("senderName", "", t("general.sender"), (row_1) => (new CellContent(0, [row_1.SenderName])), "text", "text"), new ColumnDefinition$1("inititalMessage", "", t("report.problem_report.message"), (row_2) => (new CellContent(0, [row_2.InititalMessage])), "text", "text"), new ColumnDefinition$1("serialNumber", "", t("tool.serial_number"), (row_3) => (new CellContent(0, [row_3.SerialNumber])), "text", "text"), new ColumnDefinition$1("model", "", t("tool.model"), (row_4) => (new CellContent(0, [row_4.Model])), "text", "text"), new ColumnDefinition$1("manufacturer", "", t("tool.manufacturer"), (row_5) => (new CellContent(0, [row_5.Manufacturer])), "text", "text"), new ColumnDefinition$1("displayName", "", t("tool.displayname"), (row_6) => (new CellContent(0, [row_6.DisplayName])), "text", "text"), new ColumnDefinition$1("inventoryNumber", "", t("general.inventory_number"), (row_7) => (new CellContent(0, [row_7.InventoryNumber])), "text", "text")],
        Controls: empty(),
        Options: {
            BorderStyle: "full",
            CSVDownload: true,
            ConfigurableColumns: false,
            DefaultSortColumn: ["timestamp", "asc"],
            GlobalSearch: true,
            OnRowClick: new OnTableRowClick$1(1, [(tool) => RouterModule_encodeParts(ofArray(["tools", unwrapToolId(tool.ToolId)]), 1)]),
            RowSelection: "disabled",
        },
        TableRows: TableRows,
    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])));
}

function MinimumStockQuantityReport(props) {
    let elems_2, elems_1, elems, TableRows;
    const t = useTranslation()[0];
    return createElement("div", createObj(ofArray([["className", "flex-grow-1 d-flex flex-column"], (elems_2 = [createElement("div", createObj(ofArray([["id", "minimumstockquantity"], ["className", "tab-pane"], ["children", "Minimumstockquantity"], (elems_1 = [createElement("div", createObj(ofArray([["id", "tools-table"], ["className", join(" ", ["flex", "flex-col"])], (elems = [createElement(Table, (TableRows = toArray(props.Reports), {
        ColumnDefinitions: [new ColumnDefinition$1("displayName", "", t("general.tool"), (row) => (new CellContent(0, [row.DisplayName])), "text", "text"), new ColumnDefinition$1("manufacturer", "", t("tool.manufacturer"), (row_1) => (new CellContent(0, [row_1.Manufacturer])), "text", "text"), new ColumnDefinition$1("model", "", t("tool.model"), (row_2) => (new CellContent(0, [row_2.Model])), "text", "text"), new ColumnDefinition$1("inventoryNumber", "", t("general.inventory_number"), (row_3) => (new CellContent(0, [row_3.InventoryNumber])), "text", "text"), new ColumnDefinition$1("storageStock", "", t("general.stock"), (row_4) => (new CellContent(0, [row_4.StorageStock])), "text", "text"), new ColumnDefinition$1("minimumStock", "", t("general.minimum_quantity"), (row_5) => (new CellContent(0, [row_5.MinimumStock])), "text", "text"), new ColumnDefinition$1("storageName", "", t("general.storage"), (row_6) => (new CellContent(0, [row_6.StorageName])), "text", "text"), new ColumnDefinition$1("storageSpace", "", t("tool.storage_space"), (row_7) => (new CellContent(0, [row_7.StorageSpace])), "text", "text"), new ColumnDefinition$1("supplierArticleNumber", t("tool.commercial_data"), t("general.supplier_article_number"), (dto) => (new CellContent(0, [dto.CommercialData.SupplierArticleNumber])), "text", "text"), new ColumnDefinition$1("supplier", t("tool.commercial_data"), t("general.supplier"), (dto_1) => (new CellContent(0, [dto_1.CommercialData.Supplier])), "text", "text"), new ColumnDefinition$1("purchaseDate", t("tool.commercial_data"), t("general.purchase_date"), (dto_2) => (new CellContent(5, [dto_2.CommercialData.PurchaseDate])), "text", "date"), new ColumnDefinition$1("guranteeDate", t("tool.commercial_data"), t("general.gurantee_date"), (dto_3) => (new CellContent(5, [dto_3.CommercialData.WarrantyDate])), "text", "date"), new ColumnDefinition$1("purchasePrice", t("tool.commercial_data"), t("general.purchase_price"), (dto_4) => {
            const matchValue = dto_4.CommercialData.PurchasePrice;
            return (matchValue != null) ? (new CellContent(0, [Helper_priceToString(matchValue)])) : (new CellContent(0, [""]));
        }, "text", "text")],
        Controls: empty(),
        Options: {
            BorderStyle: "full",
            CSVDownload: true,
            ConfigurableColumns: false,
            DefaultSortColumn: ["displayName", "asc"],
            GlobalSearch: true,
            OnRowClick: new OnTableRowClick$1(1, [(row_8) => {
                const matchValue_1 = row_8.ToolId;
                const matchValue_2 = row_8.ConsumableId;
                return (matchValue_1 != null) ? RouterModule_encodeParts(ofArray(["toolswithquantity", unwrapToolId(matchValue_1)]), 1) : ((matchValue_2 != null) ? RouterModule_encodeParts(ofArray(["consumables", unwrapConsumableId(matchValue_2)]), 1) : RouterModule_encodeParts(ofArray(["tools"]), 1));
            }]),
            RowSelection: "disabled",
        },
        TableRows: TableRows,
    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])));
}

function PlannedReturnDateReport(props) {
    let elems_2, elems_1, elems, TableRows;
    const t = useTranslation()[0];
    return createElement("div", createObj(ofArray([["className", "flex-grow-1 d-flex flex-column"], (elems_2 = [createElement("div", createObj(ofArray([["id", "plannedreturndate"], ["className", "tab-pane"], ["children", "plannedreturndate"], (elems_1 = [createElement("div", createObj(ofArray([["id", "tools-table"], ["className", join(" ", ["flex", "flex-col"])], (elems = [createElement(Table, (TableRows = toArray(props.Reports), {
        ColumnDefinitions: [new ColumnDefinition$1("date", "", t("general.date"), (row) => (new CellContent(5, [row.Date])), "text", "date"), new ColumnDefinition$1("receiverName", "", t("general.receiver"), (row_1) => (new CellContent(0, [row_1.ReceiverName])), "text", "text"), new ColumnDefinition$1("displayName", "", t("general.tool"), (row_2) => (new CellContent(0, [row_2.DisplayName])), "text", "text"), new ColumnDefinition$1("manufacturer", "", t("tool.manufacturer"), (row_3) => (new CellContent(0, [row_3.Manufacturer])), "text", "text"), new ColumnDefinition$1("model", "", t("tool.model"), (row_4) => (new CellContent(0, [row_4.Model])), "text", "text"), new ColumnDefinition$1("serialNumber", "", t("tool.serial_number"), (row_5) => (new CellContent(0, [row_5.SerialNumber])), "text", "text"), new ColumnDefinition$1("inventoryNumber", "", t("general.inventory_number"), (row_6) => (new CellContent(0, [row_6.InventoryNumber])), "text", "text")],
        Controls: empty(),
        Options: {
            BorderStyle: "full",
            CSVDownload: true,
            ConfigurableColumns: false,
            DefaultSortColumn: ["date", "asc"],
            GlobalSearch: true,
            OnRowClick: new OnTableRowClick$1(1, [(row_7) => RouterModule_encodeParts(ofArray(["tools", unwrapToolId(row_7.ToolId)]), 1)]),
            RowSelection: "disabled",
        },
        TableRows: TableRows,
    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])));
}

function NextReservationsReport(props) {
    let elems_2, elems_1, elems, TableRows;
    const t = useTranslation()[0];
    return createElement("div", createObj(ofArray([["className", "flex-grow-1 d-flex flex-column"], (elems_2 = [createElement("div", createObj(ofArray([["id", "nextreservations"], ["className", "tab-pane"], ["children", "nextreservations"], (elems_1 = [createElement("div", createObj(ofArray([["id", "tools-table"], ["className", join(" ", ["flex", "flex-col"])], (elems = [createElement(Table, (TableRows = toArray(props.Reports), {
        ColumnDefinitions: [new ColumnDefinition$1("startDate", "", t("general.start_date"), (row) => (new CellContent(5, [row.StartDate])), "text", "date"), new ColumnDefinition$1("ReturnDate", "", t("general.end_date"), (row_1) => (new CellContent(5, [row_1.ReturnDate])), "text", "date"), new ColumnDefinition$1("reservationName", "", t("general.name"), (row_2) => (new CellContent(0, [row_2.ReservationName])), "text", "text"), new ColumnDefinition$1("currentReceiverName", "", t("general.current_receiver"), (dto) => {
            const matchValue = dto.ReceiverInformation.CurrentReceiverOnClickPath;
            if (matchValue == null) {
                return new CellContent(0, [""]);
            }
            else {
                const onClickPath = matchValue;
                return new CellContent(2, [createElement(TextButton, {
                    ComponentState: "enabled",
                    Label: dto.ReceiverInformation.CurrentReceiverName,
                    OnClick: () => {
                        RouterModule_nav(ofArray(toArray(onClickPath)), 1, 1);
                    },
                    TestId: "",
                    Variant: "blueButton",
                })]);
            }
        }, "select", "text"), new ColumnDefinition$1("receiverName", "", t("general.receiver"), (dto_1) => (new CellContent(2, [createElement(TextButton, {
            ComponentState: "enabled",
            Label: dto_1.ReceiverInformation.ReceiverName,
            OnClick: () => {
                RouterModule_nav(ofArray(toArray(dto_1.ReceiverInformation.ReceiverNameOnClickPath)), 1, 1);
            },
            TestId: "",
            Variant: "blueButton",
        })])), "select", "text"), new ColumnDefinition$1("displayName", "", t("general.tool"), (dto_2) => (new CellContent(2, [createElement(TextButton, {
            ComponentState: "enabled",
            Label: dto_2.DisplayName,
            OnClick: () => {
                RouterModule_nav(ofArray(toArray(dto_2.ToolInformation.OnClickPath)), 1, 1);
            },
            TestId: "",
            Variant: "blueButton",
        })])), "text", "text"), new ColumnDefinition$1("manufacturer", "", t("tool.manufacturer"), (row_3) => (new CellContent(0, [row_3.Manufacturer])), "text", "text"), new ColumnDefinition$1("model", "", t("tool.model"), (row_4) => (new CellContent(0, [row_4.Model])), "text", "text"), new ColumnDefinition$1("serialNumber", "", t("tool.serial_number"), (row_5) => (new CellContent(0, [row_5.SerialNumber])), "text", "text"), new ColumnDefinition$1("inventoryNumber", "", t("general.inventory_number"), (row_6) => (new CellContent(0, [row_6.InventoryNumber])), "text", "text"), new ColumnDefinition$1("quantity", "", t("general.quantity"), (row_7) => (new CellContent(0, [defaultArg(row_7.ToolInformation.Quantity, "")])), "text", "text"), new ColumnDefinition$1("unit", "", t("general.unit"), (row_8) => (new CellContent(0, [t(row_8.ToolInformation.Unit)])), "select", "text")],
        Controls: empty(),
        Options: {
            BorderStyle: "full",
            CSVDownload: true,
            ConfigurableColumns: false,
            DefaultSortColumn: ["startDate", "asc"],
            GlobalSearch: true,
            OnRowClick: new OnTableRowClick$1(1, [(dto_3) => {
                let matchValue_1;
                return RouterModule_encodeParts(ofArray(["reservations", (matchValue_1 = dto_3.GroupReservationId, (matchValue_1 == null) ? dto_3.ReservationId : matchValue_1)]), 1);
            }]),
            RowSelection: "disabled",
        },
        TableRows: TableRows,
    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])));
}

function ReportResultContent(props) {
    let elems_1;
    const t = useTranslation()[0];
    return createElement("div", createObj(ofArray([["className", join(" ", ["h-full"])], (elems_1 = toList(delay(() => {
        let children, elems;
        if (props.State.RequestState) {
            return singleton_1(createElement(Skeleton, {
                Variant: "table",
            }));
        }
        else {
            const matchValue = props.State.ReportResult;
            const matchValue_1 = props.State.PropertyConfiguration;
            let matchResult, propertyConfiguration, reportResult;
            if (matchValue == null) {
                matchResult = 1;
            }
            else if (matchValue_1.tag === 0) {
                matchResult = 1;
            }
            else {
                matchResult = 0;
                propertyConfiguration = matchValue_1.fields[0];
                reportResult = matchValue;
            }
            switch (matchResult) {
                case 0: {
                    const matchValue_3 = reportResult;
                    switch (matchValue_3.tag) {
                        case 1:
                            return singleton_1(createElement(ProblemReportsPerToolView, {
                                Dispatch: props.Dispatch,
                                Reports: matchValue_3.fields[0],
                                State: props.State,
                            }));
                        case 2:
                            return singleton_1(createElement(AppointmentsView, {
                                Dispatch: props.Dispatch,
                                PropertyConfigurations: propertyConfiguration,
                                Reports: matchValue_3.fields[0],
                                State: props.State,
                            }));
                        case 8:
                            return singleton_1(createElement(MissedAppointmentsView, {
                                Dispatch: props.Dispatch,
                                PropertyConfigurations: propertyConfiguration,
                                Reports: matchValue_3.fields[0],
                                State: props.State,
                            }));
                        case 3:
                            return singleton_1(createElement(ToolsCreatedByDateRangeOverview, {
                                State: props.State,
                                Tools: matchValue_3.fields[0],
                            }));
                        case 4:
                            return singleton_1(createElement(SchoeffmannRetiredToolsWithQRCodeId, {
                                State: props.State,
                                Tools: matchValue_3.fields[0],
                            }));
                        case 6:
                            return singleton_1(createElement(SchoeffmannActiveAndInMaintenanceToolsWithQRCodeId, {
                                State: props.State,
                                Tools: matchValue_3.fields[0],
                            }));
                        case 5:
                            return singleton_1(createElement(ReservationsByDaterangeReport, {
                                State: props.State,
                                Tools: matchValue_3.fields[0],
                            }));
                        case 12:
                            return singleton_1(createElement(WithoutReservationsByDateRangeReport, {
                                State: props.State,
                                Tools: matchValue_3.fields[0],
                            }));
                        case 7:
                            return singleton_1(createElement(PrinzingReportAssignedToolsWithLastComment, {
                                State: props.State,
                                Tools: matchValue_3.fields[0],
                            }));
                        case 9:
                            return singleton_1(createElement(SchoeffmannUserReport, {
                                Dispatch: props.Dispatch,
                                Reports: matchValue_3.fields[0],
                                State: props.State,
                            }));
                        case 10:
                            return singleton_1(createElement(SBucherReport, {
                                Dispatch: props.Dispatch,
                                Reports: matchValue_3.fields[0],
                                State: props.State,
                            }));
                        case 11:
                            return singleton_1(createElement(WassernordConsumableReport, {
                                Dispatch: props.Dispatch,
                                Reports: matchValue_3.fields[0],
                                State: props.State,
                            }));
                        case 13:
                            return singleton_1(createElement(InventoryAssignmentByDaterange, {
                                Elements: matchValue_3.fields[0],
                            }));
                        case 14:
                            return singleton_1((children = singleton(createElement("div", createObj(ofArray([["className", join(" ", ["d-flex", "mt-2"])], (elems = [createElement(Button, {
                                ComponentState: "enabled",
                                Label: t("report.print_report"),
                                OnClick: () => {
                                    props.Dispatch(new Msg(7, []));
                                },
                                TestId: "report-printreport-button",
                                Variant: "primary",
                            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))), createElement("div", {
                                children: Interop_reactApi.Children.toArray(Array.from(children)),
                            })));
                        case 23:
                            return singleton_1(defaultOf());
                        case 15:
                            return singleton_1(createElement(ProblemReportsReport, {
                                Dispatch: props.Dispatch,
                                Reports: matchValue_3.fields[0],
                                State: props.State,
                            }));
                        case 16:
                            return singleton_1(createElement(OpenAppointmentsView, {
                                Dispatch: props.Dispatch,
                                PropertyConfigurations: propertyConfiguration,
                                Reports: matchValue_3.fields[0],
                                State: props.State,
                            }));
                        case 17:
                            return singleton_1(createElement(MinimumStockQuantityReport, {
                                Dispatch: props.Dispatch,
                                Reports: matchValue_3.fields[0],
                                State: props.State,
                            }));
                        case 18:
                            return singleton_1(createElement(PlannedReturnDateReport, {
                                Dispatch: props.Dispatch,
                                Reports: matchValue_3.fields[0],
                                State: props.State,
                            }));
                        case 19:
                            return singleton_1(createElement(NextReservationsReport, {
                                Dispatch: props.Dispatch,
                                Reports: matchValue_3.fields[0],
                                State: props.State,
                            }));
                        case 20:
                            return singleton_1(createElement(PrinzingBaustromReport, {
                                ConstructionSites: matchValue_3.fields[0].ConstructionSites,
                            }));
                        case 21: {
                            const matchValue_4 = props.State.SelectedStorageId;
                            return (matchValue_4 == null) ? singleton_1(createElement("div", {
                                children: ["No storage selected."],
                            })) : singleton_1(createElement(SwissAthleticReport, {
                                EndDate: props.State.EndDate,
                                StartDate: props.State.StartDate,
                                StorageId: matchValue_4,
                                SwissAthleticReportResult: matchValue_3.fields[0],
                            }));
                        }
                        case 22:
                            return singleton_1(createElement(ReportView, {
                                Report: matchValue_3.fields[0],
                            }));
                        default:
                            return singleton_1(createElement(ConsumableByCommissionnumberView, {
                                Dispatch: props.Dispatch,
                                Reports: matchValue_3.fields[0],
                                State: props.State,
                            }));
                    }
                }
                default:
                    return singleton_1(createElement(InformationAlert, {
                        Label: t("report.report_not_selected_msg"),
                    }));
            }
        }
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
}

function ReportControl(props) {
    let elems_7;
    const t = useTranslation()[0];
    return createElement("div", createObj(ofArray([["className", join(" ", ["max-w-[60%]"])], (elems_7 = toList(delay(() => {
        let elems;
        return append_1(singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["mb-4"])], (elems = [selectWithLabel(reportSelectProps({
            Dispatch: props.Dispatch,
            State: props.State,
        }), t("report.choose_report"), "")], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))), delay(() => {
            let elems_4, elems_3, elems_1, elems_2;
            const matchValue = props.State.SelectedReportId;
            if (matchValue != null) {
                const reportId = matchValue | 0;
                return append_1(Helper_hasReportDaterangeOption(reportId) ? singleton_1(createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_4 = [createElement(TextValue, {
                    FontWeight: "bold",
                    TestId: "",
                    Text: t("report.choose_timerange"),
                }), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "mt-4", "gap-4"])], (elems_3 = [createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_1 = [createElement(DatePicker, {
                    ComponentState: "enabled",
                    IsClearable: false,
                    Label: t("report.from"),
                    MaxDate: props.State.EndDate,
                    OnChange: (d) => {
                        if (d != null) {
                            props.Dispatch(new Msg(1, [d]));
                        }
                    },
                    TestId: "",
                    Value: props.State.StartDate,
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("div", createObj(ofArray([["className", join(" ", [])], (elems_2 = [createElement(DatePicker, {
                    ComponentState: "enabled",
                    IsClearable: false,
                    Label: t("report.to"),
                    MinDate: props.State.StartDate,
                    OnChange: (d_2) => {
                        if (d_2 != null) {
                            props.Dispatch(new Msg(2, [d_2]));
                        }
                    },
                    TestId: "",
                    Value: props.State.EndDate,
                })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))])])))) : empty_1(), delay(() => {
                    let matchValue_1, report_1, matchValue_2, options, elems_5;
                    return append_1((matchValue_1 = Helper_reportById(reportId), (matchValue_1 != null) ? (equals(matchValue_1.Type, new ReportType(21, [])) ? ((report_1 = matchValue_1, (matchValue_2 = props.State.Storages, (matchValue_2.tag === 0) ? singleton_1(createElement(Skeleton, {
                        Variant: "normal",
                    })) : ((options = map((storage) => ({
                        label: storage.Name,
                        value: storage.Id,
                    }), matchValue_2.fields[0]), singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["mt-4"])], (elems_5 = [createElement(Select, {
                        ComponentState: "enabled",
                        IsClearable: false,
                        Label: t("general.storage"),
                        NoOptionsMessage: "",
                        OnChange: (selectedOption) => {
                            props.Dispatch(new Msg(6, [map_1((option) => option.value, selectedOption)]));
                        },
                        Options: options,
                        PlaceholderKey: "",
                        TestId: "",
                        Value: unwrap(flatten(map_1((selectedStorageId) => tryFind_1((option_2) => equals(option_2.value, selectedStorageId), options), props.State.SelectedStorageId))),
                    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])]))))))))) : (empty_1())) : (empty_1())), delay(() => {
                        let elems_6;
                        return singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["mt-4"])], (elems_6 = [createElement(Button, {
                            ComponentState: "enabled",
                            Label: t("general.display"),
                            OnClick: () => {
                                props.Dispatch(new Msg(8, []));
                            },
                            TestId: "report-view-display-button",
                            Variant: "primary",
                        })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])]))));
                    }));
                }));
            }
            else {
                return singleton_1(defaultOf());
            }
        }));
    })), ["children", Interop_reactApi.Children.toArray(Array.from(elems_7))])])));
}

export function View(props) {
    let elems_1, elems;
    let patternInput;
    const init = init_1(props.Customer);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init, update_1, (_arg, _arg_1) => {
    }), undefined, undefined);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    return createElement("div", createObj(ofArray([["className", join(" ", ["h-full"])], (elems_1 = [createElement(SectionContainer, {
        Content: createElement(ReportControl, {
            Dispatch: dispatch,
            State: state_1,
        }),
    }), createElement("div", createObj(ofArray([["className", join(" ", ["h-full"])], (elems = [createElement(ReportResultContent, {
        Dispatch: dispatch,
        State: state_1,
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
}

export function ViewWithQueryParameter(props) {
    let elems_1, elems;
    let patternInput;
    const init = initWithQueryParameter(props.Customer, props.Parameter);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init, update_1, (_arg, _arg_1) => {
    }), undefined, undefined);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    return createElement("div", createObj(ofArray([["className", join(" ", ["h-full"])], (elems_1 = [createElement(SectionContainer, {
        Content: createElement(ReportControl, {
            Dispatch: dispatch,
            State: state_1,
        }),
    }), createElement("div", createObj(ofArray([["className", join(" ", ["h-full"])], (elems = [createElement(ReportResultContent, {
        Dispatch: dispatch,
        State: state_1,
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])));
}

