import { Record, Union } from "../fable_modules/fable-library-js.4.19.2/Types.js";
import { option_type, tuple_type, int32_type, record_type, bool_type, lambda_type, class_type, list_type, string_type, union_type } from "../fable_modules/fable-library-js.4.19.2/Reflection.js";
import { isEmpty, filter } from "../fable_modules/fable-library-js.4.19.2/List.js";
import { toList } from "../fable_modules/fable-library-js.4.19.2/Map.js";

export class CellDataType extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["String", "Integer", "DateTime", "Bool"];
    }
}

export function CellDataType_$reflection() {
    return union_type("Shared.Importer.CellDataType", [], CellDataType, () => [[], [], [], []]);
}

export class FieldImportState extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Ok", "Failed"];
    }
}

export function FieldImportState_$reflection() {
    return union_type("Shared.Importer.FieldImportState", [], FieldImportState, () => [[], [["translationKey", string_type]]]);
}

export class CustomFieldCheck extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["DuplicateString", "ExistsString", "ExistsStringOptional", "MatchRegex", "OrMatchRegex", "CustomAsyncFunction"];
    }
}

export function CustomFieldCheck_$reflection() {
    return union_type("Shared.Importer.CustomFieldCheck", [], CustomFieldCheck, () => [[["Item", list_type(string_type)]], [["Item", list_type(string_type)]], [["Item", list_type(string_type)]], [["Item", string_type]], [["Item1", string_type], ["Item2", string_type]], [["Item", lambda_type(string_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [FieldImportState_$reflection()]))]]]);
}

export class ImportDataDefinition extends Record {
    constructor(Key, Mandatory, CustomFieldChecks, Type, IsCustomProperty) {
        super();
        this.Key = Key;
        this.Mandatory = Mandatory;
        this.CustomFieldChecks = CustomFieldChecks;
        this.Type = Type;
        this.IsCustomProperty = IsCustomProperty;
    }
}

export function ImportDataDefinition_$reflection() {
    return record_type("Shared.Importer.ImportDataDefinition", [], ImportDataDefinition, () => [["Key", string_type], ["Mandatory", bool_type], ["CustomFieldChecks", list_type(CustomFieldCheck_$reflection())], ["Type", CellDataType_$reflection()], ["IsCustomProperty", bool_type]]);
}

export class ValidatedCell extends Record {
    constructor(Value, ValidationResult) {
        super();
        this.Value = Value;
        this.ValidationResult = ValidationResult;
    }
}

export function ValidatedCell_$reflection() {
    return record_type("Shared.Importer.ValidatedCell", [], ValidatedCell, () => [["Value", string_type], ["ValidationResult", FieldImportState_$reflection()]]);
}

export class CsvErrorImportType extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["NoRows", "NoHeaders", "HeadersNotEqual"];
    }
}

export function CsvErrorImportType_$reflection() {
    return union_type("Shared.Importer.CsvErrorImportType", [], CsvErrorImportType, () => [[], [], []]);
}

export class ImportResult extends Record {
    constructor(Headers, Rows, RowsLength, IsValid, IsImported, CsvErrorImportType) {
        super();
        this.Headers = Headers;
        this.Rows = Rows;
        this.RowsLength = (RowsLength | 0);
        this.IsValid = IsValid;
        this.IsImported = IsImported;
        this.CsvErrorImportType = CsvErrorImportType;
    }
}

export function ImportResult_$reflection() {
    return record_type("Shared.Importer.ImportResult", [], ImportResult, () => [["Headers", list_type(string_type)], ["Rows", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [tuple_type(int32_type, string_type), ValidatedCell_$reflection()])], ["RowsLength", int32_type], ["IsValid", bool_type], ["IsImported", bool_type], ["CsvErrorImportType", option_type(CsvErrorImportType_$reflection())]]);
}

export class ImportValidateResponse extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Successful", "UserLimitReached", "ToolLimitReached", "Failed"];
    }
}

export function ImportValidateResponse_$reflection() {
    return union_type("Shared.Importer.ImportValidateResponse", [], ImportValidateResponse, () => [[["Item", ImportResult_$reflection()]], [], [], []]);
}

export class ImportDataResponse extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Successful", "DataNotValid", "UserLimitReached", "ToolLimitReached", "Failed"];
    }
}

export function ImportDataResponse_$reflection() {
    return union_type("Shared.Importer.ImportDataResponse", [], ImportDataResponse, () => [[["Item", ImportResult_$reflection()]], [], [], [], []]);
}

export class ImportType extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["ImportTool", "ImportToolWithQuantity", "ImportConsumable", "ImportUser", "ImportConstructionSite", "ImportEmployee", "ImportExternalPerson", "ImportProject", "ImportRoom", "ImportSchoolClass", "ImportStudent", "ImportSubcontractor", "ImportVehicle", "ImportDemoData", "ImportLabel"];
    }
}

export function ImportType_$reflection() {
    return union_type("Shared.Importer.ImportType", [], ImportType, () => [[], [], [], [], [], [], [], [], [], [], [], [], [], [], []]);
}

export class Import extends Record {
    constructor(Id, ImportDate, IsDeleted, Type, ImportedIDs) {
        super();
        this.Id = Id;
        this.ImportDate = ImportDate;
        this.IsDeleted = IsDeleted;
        this.Type = Type;
        this.ImportedIDs = ImportedIDs;
    }
}

export function Import_$reflection() {
    return record_type("Shared.Importer.Import", [], Import, () => [["Id", class_type("System.Guid")], ["ImportDate", class_type("System.DateTime")], ["IsDeleted", bool_type], ["Type", ImportType_$reflection()], ["ImportedIDs", list_type(string_type)]]);
}

export class ImportHistoryEntry extends Record {
    constructor(Id, Timestamp, IsDeleted, ImportType, ImportedElements) {
        super();
        this.Id = Id;
        this.Timestamp = Timestamp;
        this.IsDeleted = IsDeleted;
        this.ImportType = ImportType;
        this.ImportedElements = (ImportedElements | 0);
    }
}

export function ImportHistoryEntry_$reflection() {
    return record_type("Shared.Importer.ImportHistoryEntry", [], ImportHistoryEntry, () => [["Id", class_type("System.Guid")], ["Timestamp", class_type("System.DateTime")], ["IsDeleted", bool_type], ["ImportType", string_type], ["ImportedElements", int32_type]]);
}

export class ImportDeleteResponse extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Successful", "NotSupported"];
    }
}

export function ImportDeleteResponse_$reflection() {
    return union_type("Shared.Importer.ImportDeleteResponse", [], ImportDeleteResponse, () => [[], []]);
}

export function Helper_isImportedDataValid(rows) {
    return isEmpty(filter((tupledArg) => {
        if (tupledArg[1].ValidationResult.tag === 1) {
            return true;
        }
        else {
            return false;
        }
    }, toList(rows)));
}

export function Helper_importTypeAsString(importType) {
    switch (importType.tag) {
        case 1:
            return "tool_with_quantity";
        case 2:
            return "consumable";
        case 3:
            return "user";
        case 4:
            return "construction_site";
        case 5:
            return "employee";
        case 6:
            return "external_person";
        case 7:
            return "project";
        case 8:
            return "room";
        case 9:
            return "school_class";
        case 10:
            return "student";
        case 11:
            return "subcontractor";
        case 12:
            return "vehicle";
        case 14:
            return "label";
        case 13:
            return "demo_data";
        default:
            return "tool";
    }
}

export function Helper_importTypeTranslationKey(importType) {
    switch (importType.tag) {
        case 1:
            return "importer.tool_with_quantity";
        case 2:
            return "importer.consumable";
        case 3:
            return "importer.user";
        case 4:
            return "importer.construction_site";
        case 5:
            return "importer.employee";
        case 6:
            return "importer.external_person";
        case 7:
            return "importer.project";
        case 8:
            return "importer.room";
        case 9:
            return "importer.school_class";
        case 10:
            return "importer.student";
        case 11:
            return "importer.subcontractor";
        case 12:
            return "import.vehicle";
        case 14:
            return "importer.label";
        case 13:
            return "importer.demo_data";
        default:
            return "importer.tool";
    }
}

export function Helper_toImportTypeFromString(importType) {
    switch (importType) {
        case "tool":
            return new ImportType(0, []);
        case "tool_with_quantity":
            return new ImportType(1, []);
        case "consumable":
            return new ImportType(2, []);
        case "user":
            return new ImportType(3, []);
        case "construction_site":
            return new ImportType(4, []);
        case "employee":
            return new ImportType(5, []);
        case "external_person":
            return new ImportType(6, []);
        case "project":
            return new ImportType(7, []);
        case "room":
            return new ImportType(8, []);
        case "school_class":
            return new ImportType(9, []);
        case "student":
            return new ImportType(10, []);
        case "subcontractor":
            return new ImportType(11, []);
        case "vehicle":
            return new ImportType(12, []);
        case "demo_data":
            return new ImportType(13, []);
        case "label":
            return new ImportType(14, []);
        default:
            throw new Error(`case not found: ${importType}`);
    }
}

