import { Union, Record } from "../fable_modules/fable-library-js.4.19.2/Types.js";
import { TokenDataDto_$reflection } from "../Shared/Shared.js";
import { union_type, class_type, record_type } from "../fable_modules/fable-library-js.4.19.2/Reflection.js";

export class State extends Record {
    constructor(UserData) {
        super();
        this.UserData = UserData;
    }
}

export function State_$reflection() {
    return record_type("Dashboard.Types.State", [], State, () => [["UserData", TokenDataDto_$reflection()]]);
}

export class Msg extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["FetchError"];
    }
}

export function Msg_$reflection() {
    return union_type("Dashboard.Types.Msg", [], Msg, () => [[["Item", class_type("System.Exception")]]]);
}

