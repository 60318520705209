import { createElement } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { createObj } from "../../fable_modules/fable-library-js.4.19.2/Util.js";
import { join } from "../../fable_modules/fable-library-js.4.19.2/String.js";
import { ColumnDefinition$1, CellContent, Table } from "../../Widgets/Table/Table.js";
import { empty, ofArray, toArray } from "../../fable_modules/fable-library-js.4.19.2/List.js";
import { TextButton } from "../../Components/Button.js";
import { RouterModule_nav } from "../../fable_modules/Feliz.Router.4.0.0/Router.fs.js";
import { Interop_reactApi } from "../../fable_modules/Feliz.2.7.0/Interop.fs.js";

export function ReportView(props) {
    let elems_2, elems_1, elems, TableRows;
    const t = useTranslation()[0];
    return createElement("div", createObj(ofArray([["className", "flex-grow-1 d-flex flex-column"], (elems_2 = [createElement("div", createObj(ofArray([["id", "nextreservations"], ["className", "tab-pane"], ["children", "nextreservations"], (elems_1 = [createElement("div", createObj(ofArray([["id", "tools-table"], ["className", join(" ", ["flex", "flex-col"])], (elems = [createElement(Table, (TableRows = toArray(props.Report.Rows), {
        ColumnDefinitions: [new ColumnDefinition$1("date", "", t("general.date"), (row) => (new CellContent(5, [row.Date])), "text", "date"), new ColumnDefinition$1("currentReceiverName", "", t("general.current_receiver"), (dto) => {
            const matchValue = dto.ReceiverInformation.CurrentReceiverOnClickPath;
            if (matchValue == null) {
                return new CellContent(0, [""]);
            }
            else {
                const onClickPath = matchValue;
                return new CellContent(2, [createElement(TextButton, {
                    ComponentState: "enabled",
                    Label: dto.ReceiverInformation.CurrentReceiverName,
                    OnClick: () => {
                        RouterModule_nav(ofArray(toArray(onClickPath)), 1, 1);
                    },
                    TestId: "",
                    Variant: "blueButton",
                })]);
            }
        }, "select", "text"), new ColumnDefinition$1("displayName", "", t("general.tool"), (dto_1) => (new CellContent(2, [createElement(TextButton, {
            ComponentState: "enabled",
            Label: dto_1.DisplayName,
            OnClick: () => {
                RouterModule_nav(ofArray(toArray(dto_1.ToolInformation.OnClickPath)), 1, 1);
            },
            TestId: "",
            Variant: "blueButton",
        })])), "text", "text"), new ColumnDefinition$1("manufacturer", "", t("tool.manufacturer"), (row_1) => (new CellContent(0, [row_1.Manufacturer])), "text", "text"), new ColumnDefinition$1("model", "", t("tool.model"), (row_2) => (new CellContent(0, [row_2.Model])), "text", "text"), new ColumnDefinition$1("serialNumber", "", t("tool.serial_number"), (row_3) => (new CellContent(0, [row_3.SerialNumber])), "text", "text"), new ColumnDefinition$1("inventoryNumber", "", t("general.inventory_number"), (row_4) => (new CellContent(0, [row_4.InventoryNumber])), "text", "text"), new ColumnDefinition$1("storageName", "", t("general.storage"), (row_5) => (new CellContent(0, [row_5.StorageInformation.StorageName])), "text", "text"), new ColumnDefinition$1("storageSpace", "", t("tool.storage_space"), (row_6) => (new CellContent(0, [row_6.StorageInformation.StorageSpace])), "text", "text")],
        Controls: empty(),
        Options: {
            BorderStyle: "full",
            CSVDownload: true,
            ConfigurableColumns: false,
            DefaultSortColumn: ["date", "asc"],
            GlobalSearch: true,
            RowSelection: "disabled",
        },
        TableRows: TableRows,
    }))], ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])])));
}

